import { styles } from '../constants/styles';
import React, {useState} from 'react';
import { Text, View, TouchableOpacity } from 'react-native';

import AzureFunc from '../hooks/azureFunc';
const azf = new AzureFunc();

export default function VitalsThresholds(props) {
  const tempHighDisplayed = props.user.units ?
  parseInt(((props.data.thresholds.temp.high * (9/5)) + 32).toFixed(1)) : props.data.thresholds.temp.high;

  const tempLowDisplayed = props.user.units ?
  parseInt(((props.data.thresholds.temp.low * (9/5)) + 32).toFixed(1)) : props.data.thresholds.temp.low;


  const tempUnitDisplayed = props.user.unies ? '\u2103' : '\u2109';

  const [tempHigh, setTempHigh] = useState(tempHighDisplayed);
  const [tempLow, setTempLow] = useState(tempLowDisplayed);
  const [spo2Low, setSpo2Low] = useState(props.data.thresholds.spo2.low);
  const [hrHigh, setHrHigh] = useState(props.data.thresholds.hr.high);
  const [hrLow, setHrLow] = useState(props.data.thresholds.hr.low);
  const [rrHigh, setRrHigh] = useState(props.data.thresholds.rr.high);
  const [rrLow, setRrLow] = useState(props.data.thresholds.rr.low);

  async function updateThresholds(){
    const thresholds = {
      hr:{'low': hrLow, 'high': hrHigh},
      spo2:{'low': spo2Low, 'high': 100},
      rr:{'low': rrLow, 'high': rrHigh},
      temp:{
        'low': props.user.units ? ((tempLow - 32)* (5/9)).toFixed(1) : tempLow, 
        'high': props.user.units ? ((tempHigh - 32)* (5/9)).toFixed(1) : tempHigh}
    }
    console.log({...props.data, thresholds})
    const response = await azf.updateAdmission({...props.data, thresholds})
    console.log(response)
  }

  return(
    <View style={[styles.card, styles.col]}>
        <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
          <Text style={styles.cardHeaderValue}>Thresholds</Text>
        </View>

        <View style={[styles.row,{margin:10,justifyContent:'space-between'}]}>
          <View style={[styles.col,{width:'10%',alignItems:'center'}]}>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setHrHigh(hrHigh + 1)}>
                <Text style={styles.formBtnText}>+</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setHrHigh(hrHigh - 1)}>
                <Text style={styles.formBtnText}>-</Text>
            </TouchableOpacity>
          </View>
          <View style={[styles.row,{width:'70%',justifyContent:'space-between',alignItems:'center'}]}>
            <View style={[styles.col,{alignItems:'center'}]}>
              <Text style={styles.cardHeaderValue}>{hrHigh}</Text>
              <Text style={styles.cardHeaderLabel}>High</Text>
            </View>
            <View style={[styles.col,{alignItems:'center'}]}>
              <Text style={styles.cardHeaderValue}>Heart Rate</Text>
              <Text style={styles.cardHeaderLabel}>(BPM)</Text>
            </View>
            <View style={[styles.col,{alignItems:'center'}]}>
              <Text style={styles.cardHeaderValue}>{hrLow}</Text>
              <Text style={styles.cardHeaderLabel}>Low</Text>
            </View>
          </View>
          <View style={[styles.col,{width:'10%',alignItems:'center'}]}>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setHrLow(hrLow + 1)}>
                <Text style={styles.formBtnText}>+</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setHrLow(hrLow - 1)}>
                <Text style={styles.formBtnText}>-</Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={[styles.row,{margin:10,justifyContent:'space-between'}]}>
          <View style={[styles.col,{width:'10%',alignItems:'center'}]}>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setTempHigh(tempHigh + 0.5)}>
                <Text style={styles.formBtnText}>+</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setTempHigh(tempHigh - 0.5)}>
                <Text style={styles.formBtnText}>-</Text>
            </TouchableOpacity>
          </View>
          <View style={[styles.row,{width:'70%',justifyContent:'space-between',alignItems:'center'}]}>
            <View style={[styles.col,{alignItems:'center'}]}>
              <Text style={styles.cardHeaderValue}>{tempHigh}</Text>
              <Text style={styles.cardHeaderLabel}>High</Text>
            </View>
            <View style={[styles.col,{alignItems:'center'}]}>
              <Text style={styles.cardHeaderValue}>Temperature</Text>
              <Text style={styles.cardHeaderLabel}>{'(' + tempUnitDisplayed + ')'}</Text>
            </View>
            <View style={[styles.col,{alignItems:'center'}]}>
              <Text style={styles.cardHeaderValue}>{tempLow}</Text>
              <Text style={styles.cardHeaderLabel}>Low</Text>
            </View>
          </View>
          <View style={[styles.col,{width:'10%',alignItems:'center'}]}>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setTempLow(tempLow + 0.5)}>
                <Text style={styles.formBtnText}>+</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setTempLow(tempLow - 0.5)}>
                <Text style={styles.formBtnText}>-</Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={[styles.row,{margin:10,justifyContent:'space-between'}]}>
          <View style={[styles.col,{width:'10%',alignItems:'center'}]}>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setRrHigh(rrHigh + 1)}>
                <Text style={styles.formBtnText}>+</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setRrHigh(rrHigh - 1)}>
                <Text style={styles.formBtnText}>-</Text>
            </TouchableOpacity>
          </View>
          <View style={[styles.row,{width:'70%',justifyContent:'space-between',alignItems:'center'}]}>
            <View style={[styles.col,{alignItems:'center'}]}>
              <Text style={styles.cardHeaderValue}>{rrHigh}</Text>
              <Text style={styles.cardHeaderLabel}>High</Text>
            </View>
            <View style={[styles.col,{alignItems:'center'}]}>
              <Text style={styles.cardHeaderValue}>Respiration</Text>
              <Text style={styles.cardHeaderLabel}>(BrPM)</Text>
            </View>
            <View style={[styles.col,{alignItems:'center'}]}>
              <Text style={styles.cardHeaderValue}>{rrLow}</Text>
              <Text style={styles.cardHeaderLabel}>Low</Text>
            </View>
          </View>
          <View style={[styles.col,{width:'10%',alignItems:'center'}]}>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setRrLow(rrLow + 1)}>
                <Text style={styles.formBtnText}>+</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setRrLow(rrLow - 1)}>
                <Text style={styles.formBtnText}>-</Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={[styles.row,{margin:10,justifyContent:'space-between'}]}>
          <View style={[styles.col,{width:'10%',alignItems:'center'}]}>
            <View style={[styles.formBtnSmall, {width:'100%',opacity:0}]} >
                <Text style={styles.formBtnText}>+</Text>
            </View>
            <View style={[styles.formBtnSmall, {width:'100%',opacity:0}]} >
                <Text style={styles.formBtnText}>-</Text>
            </View>
          </View>
          <View style={[styles.row,{width:'70%',justifyContent:'space-between',alignItems:'center'}]}>
            <View style={[styles.col,{alignItems:'center'}]}>
              <Text style={styles.cardHeaderValue}>{100}</Text>
              <Text style={styles.cardHeaderLabel}>High</Text>
            </View>
            <View style={[styles.col,{alignItems:'center'}]}>
              <Text style={styles.cardHeaderValue}>Pulse Ox</Text>
              <Text style={styles.cardHeaderLabel}>(%SPO<Text style={styles.cardHeaderLabelSub}>2</Text>)</Text>
            </View>
            <View style={[styles.col,{alignItems:'center'}]}>
              <Text style={styles.cardHeaderValue}>{spo2Low}</Text>
              <Text style={styles.cardHeaderLabel}>Low</Text>
            </View>
          </View>
          <View style={[styles.col,{width:'10%',alignItems:'center'}]}>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setSpo2Low(spo2Low + 1)}>
                <Text style={styles.formBtnText}>+</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.formBtnSmall, {width:'100%'}]} onPress={() => setSpo2Low(spo2Low - 1)}>
                <Text style={styles.formBtnText}>-</Text>
            </TouchableOpacity>
          </View>
        </View>

        <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => updateThresholds()}>
            <Text style={styles.formBtnText}>Update</Text>
        </TouchableOpacity>

    </View>
  )
}