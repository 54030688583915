import { styles } from '../constants/styles';
import * as React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';

export default function PatientRow(props) {
  const [shadow, setShadow] = React.useState('#ededed')
  function hoverCard(e) {

    setShadow('white');
  }

  function leaveCard(e) {

    setShadow('#ededed');
  }

  let latestUpdateTime = props.device.time ? new Date(props.device.time).toISOString() : ''

  return (
    <TouchableOpacity style={[styles.tableRow, {backgroundColor: shadow}]} onPress={() => props.selectAdmission(props.device)}
      onMouseOver={(e) => hoverCard(e)} onMouseLeave={(e) => leaveCard(e)} >
      <View style={styles.tableTextContainer}>
        <Text  style={styles.tableText}>{props.device.patient.id}</Text>
      </View>

      <View style={styles.tableTextContainer}>
        <Text  style={styles.tableText}>{props.device.deviceId}</Text>
      </View>

      <View style={[styles.tableTextContainer]}>
        <Text  style={styles.tableText}>{props.device.bed}</Text>
      </View>

      <View style={[styles.tableTextContainer]}>
        <Text  style={styles.tableText}>{props.device.room}</Text>
      </View>
    </TouchableOpacity>
  );
}