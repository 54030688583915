export function classifyAdmission (admission){
  function checkHR(hr) {
    let category = ''
    if(hr < admission.thresholds.hr.low ||
      hr > admission.thresholds.hr.high){
      category = 'unstable'
    }else if (hr < admission.thresholds.hr.low * 1.1 ||
       hr > admission.thresholds.hr.high * 0.9){
      category = 'atRisk'
    }
    return category
  }

  function checkSPO2(spo2) {
    let category = ''
    if(spo2 < admission.thresholds.spo2.low){
      category = 'unstable'
    }else if (spo2 < admission.thresholds.spo2.low * 1.02){
      category = 'atRisk'
    }
    return category
  }

  function checkResp(resp) {
    let category = ''
    if(resp < admission.thresholds.rr.low ||
      resp > admission.thresholds.rr.high * 1.1){
      category = 'unstable'
    }else if (resp < admission.thresholds.rr.low ||
      resp > admission.thresholds.rr.high * 0.9){
      category = 'atRisk'
    }
    return category
  }

  function checkTemp(temp) {
    let category = ''
    if(temp < admission.thresholds.temp.low ||
      temp > admission.thresholds.temp.high){
      category = 'unstable'
    }else if (temp < admission.thresholds.temp.low + 0.5 ||
      temp > admission.thresholds.temp.high - 0.5){
      category = 'atRisk'
    }
    return category
  }

  const hr = checkHR(admission.hr)
  const spo2 = checkSPO2(admission.spo2)
  const resp = checkResp(admission.rr)
  const temp = checkTemp(admission.temperature)

  const timeStatusAsOffline = 1*60*1000; //Time to verify status in miliseconds
  //console.log("device time: " + new Date(admission.time).toISOString())
  //console.log("server time: " + new Date().toISOString())
  if (new Date(admission.time) <= new Date() - timeStatusAsOffline){
    admission.category = 'offline'
  }else if(hr == 'unstable' || spo2 == 'unstable' || resp == 'unstable' || temp == 'unstable'){
    admission.category = 'unstable'
  }else if (hr == 'atRisk' || spo2 == 'atRisk' || resp == 'atRisk' || temp == 'atRisk'){
    admission.category = 'atRisk'
  }else {
    admission.category = 'stable'
  }
  //console.log(admission)
  return admission

}
