import { styles } from '../constants/styles';
import * as React from 'react';
import { Text, TextInput, View, TouchableOpacity } from 'react-native';
import NumberFormat from "react-number-format";
import AzureFunc from '../hooks/azureFunc';

const azf = new AzureFunc();

export default function PatientInfoEditable(props) {

  const weightUnit = !props.user.units ? 'Kg' : 'lbs';
  const heightUnit = !props.user.units ? 'm' : 'ft';

  const [patientInfo, setPatientInfo] = React.useState(props.data);
  const [message, setMessage] = React.useState("");
  const patientHeight = props.data.patient ? props.data.patient.height ? props.data.patient.height : 0 : 0;
  const patientWeight = props.data.patient ? props.data.patient.weight ? props.data.patient.weight : 0 : 0;

  React.useEffect(() => {
    if(props.user.units){
      setPatientInfo({...patientInfo, patient:{...patientInfo.patient, weight: parseFloat(patientWeight / 0.453592).toFixed(1), 
        height: parseFloat((patientHeight / 0.3048).toFixed(1))}})
    } else {
      setPatientInfo({...patientInfo, patient:{...patientInfo.patient, weight: patientWeight.toFixed(1), 
        height: patientHeight.toFixed(2)}})
    }
  },[])
  
  async function updatePatientAdmission(){
    let newPatientInfo = patientInfo
    // Convert all measures to metric floats
    // Parse Weight
    if(props.user.units){
      newPatientInfo.patient.weight = parseFloat((parseFloat(newPatientInfo.patient.weight)*0.453592).toFixed(1))
    } else {
      newPatientInfo.patient.weight = parseFloat(newPatientInfo.patient.weight)
    }
    // Parse Height
    if(props.user.units){
      let feet = patientInfo.height ? parseInt(newPatientInfo.patient.height.split('ft')[0]) : 0
      let inches = patientInfo.height ? parseInt(newPatientInfo.patient.height.split('ft')[1].slice(0,-2)) : 0
      if(inches == 12){
        feet = feet + 1
        inches = 0
      } else if (inches > 12) {
        setMessage('Inches can not be larger than 12.')
        return
      } else if (!inches){
        inches = 0
      }
      const totalFeet = feet + (inches/12)
      newPatientInfo.patient.height = parseFloat((totalFeet * 0.3048).toFixed(2))
    } else {
      newPatientInfo.patient.height = parseFloat(newPatientInfo.patient.height)
    }
    // Parse Age as integer
    newPatientInfo.patient.age = parseInt(newPatientInfo.patient.age)

    newPatientInfo.updated = new Date().toISOString();
    await azf.updateAdmission(newPatientInfo)
    props.setData(newPatientInfo)
    props.setViewPatientInfoModal(false)
  }

  async function editAge(age){
    setPatientInfo({...patientInfo, patient:{...patientInfo.patient, age, dob:""}})
  }

  async function editDOB(dob){
    let newAge = calculateAgeFromDOB(dob);
    if (newAge){
       setPatientInfo({...patientInfo, patient:{...patientInfo.patient, dob, age:newAge}})
    } else {
      setPatientInfo({...patientInfo, patient:{...patientInfo.patient, dob}})
    }    
  }

  const calculateAgeFromDOB = dobText => {
    dobText = dobText.replace("_","")
    if(dobText.split('-').length < 3){
        return false
    } else if (dobText.split('-')[2].length != 4){
        return false
    }
    try {
        let dobDate = new Date(dobText)
        let currentDate = new Date()
        let calcAge = parseInt((currentDate - dobDate)/(60*60*24*365*1000))
        return calcAge
    } catch (e){
        console.log(e)
        return false
    }
  }

  return (
    <View>
      <Text style={styles.cardHeaderLabel}>Patient ID:</Text>
      <TextInput style={styles.formInput} id={"patientIdEdit"} 
        onChangeText={text => setPatientInfo({...patientInfo, patient:{...patientInfo.patient, id:text}})}
        value={patientInfo.patient.id} autoCapitalize='none'/>

      <Text style={styles.cardHeaderLabel}>Patient Name:</Text>
      <TextInput style={styles.formInput} id={"patientNameEdit"} 
        onChangeText={text => setPatientInfo({...patientInfo, patient:{...patientInfo.patient, name:text}})}
        value={patientInfo.patient.name} autoCapitalize='none'/>

      <Text style={styles.cardHeaderLabel}>Room/Bed:</Text>
      <TextInput style={styles.formInput} id={"patientRoomEdit"} 
      onChangeText={text => setPatientInfo({...patientInfo, room:text})}
      value={patientInfo.room} autoCapitalize='none'/>

      <Text style={styles.cardHeaderLabel}>Gender:</Text>
      <TextInput style={styles.formInput} id={"patientGenderEdit"} 
        onChangeText={text => setPatientInfo({...patientInfo, patient:{...patientInfo.patient, gender:text}})}
        value={patientInfo.patient.gender} autoCapitalize='none'/>

      <Text style={styles.cardHeaderLabel}>Age (years):</Text>
      <TextInput style={styles.formInput} id={"patientAgeEdit"} 
        onChangeText={text => editAge(text)}
        value={patientInfo.patient.age ?  patientInfo.patient.age.toString() : ''} autoCapitalize='none'/>

      <Text style={styles.cardHeaderLabel}>DOB:</Text>
      <NumberFormat customInput={TextInput} format={"##-##-####"}
        allowEmptyFormatting={false} mask="_" label="patientDOBEdit" name="patientDOBEdit"
        type="text" value={patientInfo.patient.dob} onChangeText={text => editDOB(text)}
        style={styles.formInput}   placeholder='dob (MM-DD-YYYY)' placeholderTextColor="gray" />

      <Text style={styles.cardHeaderLabel}>Weight ({weightUnit}):</Text>
      {!props.user.units ?
        <NumberFormat customInput={TextInput} format={"###.# Kg"} id={"patientWeightEditMetric"} 
          allowEmptyFormatting={false} mask="_" label="weight" name="weight"
          type="text" value={patientInfo.patient.weight ? patientInfo.patient.weight.toString() : ""} 
          onValueChange={values => setPatientInfo({...patientInfo, patient:{...patientInfo.patient, weight:values.formattedValue}})}
          style={styles.formInput}   placeholder='weight (Kg)' placeholderTextColor="gray" />
        :
        <NumberFormat customInput={TextInput} format={"###.# lbs"} id={"patientWeightEditImperial"} 
          allowEmptyFormatting={false} mask="_" label="weight" name="weight"
          type="text" value={patientInfo.patient.weight ? patientInfo.patient.weight.toString() : ""} 
          onValueChange={values => setPatientInfo({...patientInfo, patient:{...patientInfo.patient, weight:values.formattedValue}})}
          style={styles.formInput}   placeholder='weight (lbs)' placeholderTextColor="gray" />
      }

      <Text style={styles.cardHeaderLabel}>Height ({heightUnit}):</Text>
      {!props.user.units ?
        <NumberFormat customInput={TextInput} format={"#.# m"} id={"patientHeightEditImperial"} 
          allowEmptyFormatting={false} mask="_" label="height" name="height"
          type="text" value={patientInfo.patient.height ? patientInfo.patient.height.toString() : ""} 
          onValueChange={values => setPatientInfo({...patientInfo, patient:{...patientInfo.patient, height:values.formattedValue}})}
          style={styles.formInput}   placeholder='height (m)' placeholderTextColor="gray" />
        :
        <NumberFormat customInput={TextInput} format={"#ft ##in"} id={"patientHeightEditImperial"} 
          allowEmptyFormatting={false} mask="_" label="height" name="height"
          type="text" value={patientInfo.patient.height ? patientInfo.patient.height.toString() : ""} 
          onValueChange={values => setPatientInfo({...patientInfo, patient:{...patientInfo.patient, height:values.formattedValue}})}
          style={styles.formInput}   placeholder='height (ft)' placeholderTextColor="gray" />
      }

      <Text style={styles.cardHeaderLabel}>Diagnosis:</Text>
      <TextInput style={styles.formInput} id={"patientDiagnosisEdit"} 
        onChangeText={text => setPatientInfo({...patientInfo, diagnosis:text})}
        value={patientInfo.diagnosis} autoCapitalize='none'/>

      <View style={[styles.col, {alignItems:'center', justifyContent:'center'}]}>
        <Text style={styles.errorMessage}>{message}</Text>
      </View>
      <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => updatePatientAdmission()}>
        <Text style={styles.formBtnText}>Update</Text>
      </TouchableOpacity>
      <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => props.setViewPatientInfoModal(false)}>
          <Text style={styles.formBtnText}>Cancel</Text>
      </TouchableOpacity>
    </View>

  );
}