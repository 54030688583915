import { processColor } from "react-native";

export const msalConfig = {
    auth: {
      clientId: "d89dc686-ee9a-4942-b76a-4a74dac35dc3",
      authority: "https://login.microsoftonline.com/cdcab5e8-5dfd-4e99-bc49-053df6ab0466", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: process.env.AZURE_REDIRECT_URI_VITLS
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read", "api://0848ecb5-3525-45d2-a5c3-8185ff26a97e/Invoke"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };