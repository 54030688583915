import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    Root: {
      path: '',
      screens: {
        User: 'user',
        Dashboard: 'dashboard',
        Admissions: 'admissions',
        AddPatient: 'addPatient',
        PatientData: 'patientData',
        Admin: 'admin'
      },
    },
  },
};
