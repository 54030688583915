import * as WebBrowser from 'expo-web-browser';
import React, { useState, useEffect } from 'react';
import { Image, Text, TouchableOpacity, View, TextInput} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {Picker} from '@react-native-community/picker';
import { MonoText } from '../components/StyledText';
import { styles } from '../constants/styles';
import QrReader from 'react-qr-reader'
import NumberFormat from "react-number-format";

import AzureFunc from '../hooks/azureFunc';
const azf = new AzureFunc();

export default function AddPatient(props, { navigation, route }) {

  if(!props.user.selectedRegistration){
    return(
      <View style={[styles.col, {alignItems:'center', justifyContent:'center', height:300}]}>
        <Text style={styles.message}>No admission center selected. Set one in User tab.</Text>
      </View>
    )
  }

  const [patientID, setPatientID] = useState('');
  const [patientName, setPatientName] = useState('');
  const [room, setRoom] = useState('');
  const [device, setDevice] = useState('');
  const [gender, setGender] = useState('gender');
  const [age, setAge] = useState('');
  const [dob, setDOB] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [note, setNote] = useState('');
  const [message, setMessage] = useState('');
  const [readQR, setReadQR] = useState(false);
  const [mobile, setMobile] = useState('');

  async function addPatient(){
    setMessage('')
    let patientInfo = {'id':patientID, 'name':patientName, dob, age, 
      height:height.formattedValue, weight:weight.formattedValue, gender};
    
    if(props.auth.isSignout){
      props.auth.signOut()
      navigation.navigate('login')
    }else{
      // Check for empty fields
      let emptyField = false
      if(!patientInfo["id"]){
        setMessage('Please fill out Patient ID (MRN) field.')
        emptyField = true
        return
      }
      if(!device){
        setMessage('Please fill out Device ID field.')
        emptyField = true
        return
      }
      //console.log(JSON.stringify(patientInfo))
      // Process admit new patient
      if(!emptyField){
        // Convert all measures to metric floats
        if(props.user.units){
          patientInfo.weight = parseFloat((parseFloat(patientInfo.weight)/2.2).toFixed(1))
        } else {
          patientInfo.weight = parseFloat(patientInfo.weight)
        }
        console.log(patientInfo)
        if(props.user.units){
          let feet = patientInfo.height ? parseInt(patientInfo.height.split('ft')[0]) : 0
          let inches = patientInfo.height ? parseInt(patientInfo.height.split('ft')[1].slice(0,-2)) : 0
          if(inches == 12){
            feet = feet + 1
            inches = 0
          } else if (inches > 12) {
            setMessage('Inches can not be larger than 12.')
            return
          } else if (!inches){
            inches = 0
          }
          const totalFeet = feet + (inches/12)
          patientInfo.height = parseFloat((totalFeet / 3.281).toFixed(1))
        } else {
          patientInfo.height = parseFloat(patientInfo.height)
        }
        //console.log(JSON.stringify(patientInfo))
        // Parse Age as integer
        patientInfo.age = parseInt(patientInfo.age)

        //console.log(patientInfo)
        const admissionInfo = {room, diagnosis}

        // Check if active admission with same patientId exists
        const activeAdmissions = await azf.getActiveAdmissions(props.user.selectedRegistration.admissionCenterId,
          props.user.selectedRegistration.wardId, 1000);
        if (activeAdmissions.data.filter(adm => adm.patient.id == patientID).length > 0){
          setMessage("An active admission with same patient ID already exists.")
          return
        }

        const deviceInfo = await azf.getDevice(device.toLowerCase().trim())
        if (!deviceInfo.data.length){
          setMessage("Device ID not found. Check label for ID or contact Vitls.")
          return
        }

        const response = await azf.admitPatient(props.user.selectedRegistration.admissionCenterId,
          props.user.selectedRegistration.wardId, 
          device.toLowerCase().trim(), patientInfo, admissionInfo);

        if(response.id){
          if (note){
            const noteInfo = {
              "created": new Date().toISOString(),
              "admissionCenterId": props.user.selectedRegistration.admissionCenterId,
              "admissionId": response.id,
              "patientId": patientID,
              "note": note,
              "creator": props.user.id // userId
            }
            const noteResponse = await azf.addNote(noteInfo);
          }
          // Erase all fields
          setPatientID('');setAge('');
          setWeight({formattedValue: '', value: '', floatValue: 0});
          setHeight({formattedValue: '', value: '', floatValue: 0});
          setGender('gender');setDiagnosis('');setPatientName('');
          setDOB("");setRoom('');setDevice('');setNote('');
          props.navigation.jumpTo('Dashboard')
        }
        else {
          setMessage(response)
        }
        
      }
    }
    
  }

  const calculateAgeFromDOB = dobText => {
    setDOB(dobText.replace("_",""))
    dobText = dobText.replace("_","")
    if(dobText.split('-').length < 3){
      return
    } else if (dobText.split('-')[2].length != 4){
      return
    }
    try {
      let dobDate = new Date(dobText)
      let currentDate = new Date()
      let calcAge = parseInt((currentDate - dobDate)/(60*60*24*365*1000))
      setAge(calcAge)
      setMessage("")
    } catch (e){
      console.log(e)
      setMessage("Please set DOB to MM-DD-YYYY")
    }
  }

  const handleSetAge = ageText => {
    setAge(ageText)
    setDOB("")
  }

  const handleScan = data => {
    if (data) {
      const parsedDeviceID = data.split('(21)')[1]
      setDevice(parsedDeviceID)
      setReadQR(false)
    }
  }
  const handleError = err => {
    console.error(err)
    setMessage("Could not start QR Scan")
    setReadQR(false)
  }

  return (
    <View style={styles.container}>
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <View style={[styles.form,{flexDirection:'row', flexWrap:'wrap', marginTop:20}]}>
          <View style={[styles.col, {width:'45%', minWidth: 400}]}>
            <View style={styles.formContainerFull}>
              <TextInput style={styles.formInput} placeholder='patient ID'
                placeholderTextColor = "gray"
                id="patientID" onChangeText={text => setPatientID(text)}
                value={patientID} autoCapitalize='none'/>
            </View>
            <View style={styles.formContainerFull}>
              <TextInput style={styles.formInput} placeholder='patient name (last, first)'
                placeholderTextColor = "gray"
                id="patientName" onChangeText={text => setPatientName(text)}
                value={patientName} autoCapitalize='none'/>
            </View>
            <View style={styles.formContainerFull}>
              {!props.user.units ?
                <NumberFormat customInput={TextInput} format={"###.# Kg"}
                  allowEmptyFormatting={false} mask="_" label="weight" name="weight"
                  type="text" value={weight.formattedValue} onValueChange={values => setWeight(values)}
                  style={styles.formInput}   placeholder='weight (Kg)' placeholderTextColor="gray" />
                :
                <NumberFormat customInput={TextInput} format={"###.# lbs"}
                  allowEmptyFormatting={false} mask="_" label="weight" name="weight"
                  type="text" value={weight.formattedValue} onValueChange={values => setWeight(values)}
                  style={styles.formInput}   placeholder='weight (lbs)' placeholderTextColor="gray" />
              }
            </View>
            <View style={styles.formContainerFull}>
              {!props.user.units ?
                <NumberFormat customInput={TextInput} format={"#.# m"}
                  allowEmptyFormatting={false} mask="_" label="height" name="height"
                  type="text" value={height.formattedValue} onValueChange={values => setHeight(values)}
                  style={styles.formInput}   placeholder='height (m)' placeholderTextColor="gray" />
                :
                <NumberFormat customInput={TextInput} format={"#ft ##in"}
                  allowEmptyFormatting={false} mask="_" label="height" name="height"
                  type="text" value={height.formattedValue} onValueChange={values => setHeight(values)}
                  style={styles.formInput}   placeholder='height (ft)' placeholderTextColor="gray" />
              }
            </View>
            <View style={styles.formContainerFull}>
              <Picker selectedValue={gender}  style={[styles.picker,{color: gender == 'gender' ? 'gray' : 'black'}]}
                onValueChange={(itemValue) => setGender(itemValue)} >
                 <Picker.Item label={'gender'} value={'gender'} />
                 <Picker.Item  label={'Female'} value={'f'} />
                 <Picker.Item  label={'Male'} value={'m'} />
                 <Picker.Item  label={'Undisclosed'} value={'u'} />
              </Picker>
            </View>
            <View style={styles.formContainerFull}>
              <TextInput style={styles.formInput} placeholder='diagnosis'
                placeholderTextColor = "gray"
                id="diagnosis" onChangeText={text => setDiagnosis(text)}
                value={diagnosis} autoCapitalize='none'/>
            </View>
          </View>
          <View style={[styles.col, {width:'45%', minWidth: 400}]}>
            <View style={styles.formContainerFull}>
              <NumberFormat customInput={TextInput} format={"##-##-####"}
                allowEmptyFormatting={false} mask="_" label="dob" name="dob"
                type="text" value={dob} onChangeText={text => calculateAgeFromDOB(text)}
                style={styles.formInput}   placeholder='dob (MM-DD-YYYY)' placeholderTextColor="gray" />
            </View>
            <View style={styles.formContainerFull}>
              <NumberFormat customInput={TextInput} format={"### yrs"}
                allowEmptyFormatting={false} mask="_" label="age" name="age"
                type="text" value={age} onChangeText={text => handleSetAge(text)}
                style={styles.formInput}   placeholder='age (yrs)' placeholderTextColor="gray" />
            </View>
            <View style={styles.formContainerFull}>
              <TextInput style={styles.formInput} placeholder='room/bed'
                placeholderTextColor = "gray"
                id="room" onChangeText={text => setRoom(text)}
                value={room} autoCapitalize='none'/>
            </View>
            <View style={[styles.formContainerFull,{flexDirection:'row'}]}>
              <View style={styles.formContainer}>
                <TextInput style={styles.formInput} placeholder='device ID'
                  placeholderTextColor = "gray"
                  id="device" onChangeText={text => setDevice(text)}
                  value={device} autoCapitalize='none'/>
              </View>
                <TouchableOpacity style={styles.formBtn} onPress={() => setReadQR(!readQR)}>
                    <Text style={styles.formBtnText}>Scan QR</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.formContainerFull}>
              <TextInput style={[styles.formInput, {'height':120}]} placeholder='notes'
                placeholderTextColor = "gray" multiline={true}
                id="note" onChangeText={text => setNote(text)}
                value={note} autoCapitalize='none'/>
            </View>
          </View>
        </View>
        <View style={[styles.col]}>
          <Text style={styles.errorMessage}>{message}</Text>
        </View>
        <TouchableOpacity style={styles.formBtn} title="Signup" onPress={() => addPatient()}>
            <Text style={styles.formBtnText}>Add Patient</Text>
        </TouchableOpacity>
        {readQR ?
          <View style={{ width: '100%', position: 'absolute', alignSelf:'center', backgroundColor:'white'}}>
            <QrReader delay={300} onError={handleError} onScan={handleScan}  style={{ width: '50%', alignSelf:'center'}} />
            <TouchableOpacity style={styles.formBtn} onPress={() => setReadQR(false)}>
                <Text style={styles.formBtnText}>Close</Text>
            </TouchableOpacity>
          </View>
          :
          null
        }
      </ScrollView>
    </View>
  );
}
