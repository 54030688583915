import { styles } from '../constants/styles';
import * as React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';

export default function VitalsCard(props) {

  let time = new Date(props.data.time).toLocaleString();
  let temp = props.data.temperature;
  let hr = Math.round(props.data.hr);
  let resp = Math.round(props.data.rr);
  let spo2 = Math.round(props.data.spo2);
  let bat = Math.round(((props.data.battery) - 2.2)*(100/0.8))

  if (bat > 100){
    bat = 100;
  }
  if (bat < 0){
    bat = '--';
  }

  var batStyle = styles.green;
  if(bat < 30){
    batStyle = styles.red
  }else if (bat < 60){
    batStyle = styles.orange
  }

  var hrStyle = styles.green;
  if(hr < props.data.thresholds.hr.low||
    hr > props.data.thresholds.hr.high){
    hrStyle = styles.red
  }else if (hr < props.data.thresholds.hr.low * 1.1 ||
     hr > props.data.thresholds.hr.high * 0.9){
    hrStyle = styles.orange
  }


  var spo2Style = styles.green;
  if(spo2 < props.data.thresholds.spo2.low){
    spo2Style = styles.red
  }else if (spo2 < props.data.thresholds.spo2.low * 1.02){
    spo2Style = styles.orange
  }

  var respStyle = styles.green;
  if(resp < props.data.thresholds.rr.low ||
    resp > props.data.thresholds.rr.high * 1.1){
    respStyle = styles.red
  }else if (resp < props.data.thresholds.rr.low ||
    resp > props.data.thresholds.rr.high * 0.9){
    respStyle = styles.orange
  }

  var tempStyle = styles.green;
  if(temp < props.data.thresholds.temp.low ||
    temp > props.data.thresholds.temp.high){
    tempStyle = styles.red
  }else if (temp < props.data.thresholds.temp.low + 0.5||
    temp > props.data.thresholds.temp.high - 0.5){
    tempStyle = styles.orange
  }

  // Check for unrealistic vital sign values
  if (!props.options.units){
    temp = temp.toFixed(1)
    if (temp <= 10 || temp >= 45 ) {
      temp = '--'
      tempStyle = styles.gray
    }
  }else {
    temp = ((temp * (9/5)) + 32).toFixed(1)
    if (temp <= 50 || temp >= 113 ) {
      temp = '--'
      tempStyle = styles.gray
    }
  }

  if (hr <= 10 || hr >= 300){
    hr = '--'
    hrStyle = styles.gray
  }


  if (resp <= 2 || resp >= 40){
    respStyle = styles.gray
    resp = '--'
  }

  if (spo2 <= 40 || spo2 > 100){
    spo2Style = styles.gray
    spo2 = '--'
  }



  //console.log(props.data)
  return (
    <View style={[styles.cardLatest, styles.col, {alignSelf:'center'}]}>
      <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
        <Text style={styles.cardHeaderValue}>Latest Vitals: {time}</Text>
      </View>
      <View style={styles.row}>
        <View style={[styles.col, styles.cardVitalLatest]}>
          <Text style={styles.cardLabel}>PULSE</Text>
          <Text style={[styles.cardValue, hrStyle]}>{hr}</Text>
          <Text style={[styles.cardUnit, hrStyle]}>BPM</Text>
        </View>


        <View style={[styles.col, styles.cardVitalLatest]}>
          <Text style={styles.cardLabel}>SATS</Text>
          <Text style={[styles.cardValue, spo2Style]}>{spo2}</Text>
          <Text style={[styles.cardUnit, spo2Style]}>%SPO<Text style={styles.cardUnitSub}>2</Text></Text>
        </View>

        <View style={[styles.col, styles.cardVitalLatest]}>
          <Text style={styles.cardLabel}>RESP</Text>
          <Text style={[styles.cardValue, respStyle]}>{resp}</Text>
          <Text style={[styles.cardUnit, respStyle]}>BrPM</Text>
        </View>

        <View style={[styles.col, styles.cardVitalLatest]}>
          <Text style={styles.cardLabel}>TEMP</Text>
          <Text style={[styles.cardValue, tempStyle]}>
            {temp}
          </Text>
          <Text style={[styles.cardUnit, tempStyle]}>
            {!props.options.units ? '\u2103' : '\u2109'}
          </Text>
        </View>

        <View style={[styles.col, styles.cardVitalLatest]}>
          <Text style={styles.cardLabel}>BAT</Text>
          <Text style={[styles.cardValue, batStyle]}>{bat}</Text>
          <Text style={[styles.cardUnit, batStyle]}>%</Text>
        </View>
      </View>
    </View>
  );
}
