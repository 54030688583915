import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { Platform, StatusBar, View, Text, Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useCachedResources from './hooks/useCachedResources';
import BottomTabNavigator from './navigation/BottomTabNavigator';
import LinkingConfiguration from './navigation/LinkingConfiguration';

import SignInScreen from './screens/Login';
import SignUpScreen from './screens/Signup';

import { styles } from './constants/styles';

import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const Stack = createStackNavigator();

const AuthContext = React.createContext();

function SplashScreen() {
  return (
    <View>
      <Text>Loading...</Text>
    </View>
  );
}


export default function App(props) {
  const isLoadingComplete = useCachedResources();

  const [state, dispatch] = React.useReducer(
   (prevState, action) => {
     switch (action.type) {
       case 'RESTORE_USER':
         return {
           ...prevState,
           user: action.profile,
           isLoading: false,
         };
       case 'SIGN_IN':
         return {
           ...prevState,
           isSignout: false,
           user: action.profile,
         };
       case 'SIGN_OUT':
         return {
           ...prevState,
           isSignout: true,
           user: null,
         };
       case 'UPDATE':
         return {
           ...prevState,
           user: action.profile,
         };
     }
   },
   {
     isLoading: true,
     isSignout: false,
     user: null,
   }
 );

 React.useEffect(() => {
   const bootstrapAsync = async () => {
     let user;

     try {
       user = JSON.parse(await AsyncStorage.getItem('vitlsUser'));
       const lastLoginDate = new Date(user.stats.lastLoginTime);
       console.log("Last login Date: " + lastLoginDate);
       const logoutTimeout = 20; // minutes to keep user logged in
       const currentDate = new Date();
       console.log("Current Date: " + currentDate);
       if (lastLoginDate < currentDate.setMinutes(currentDate.getMinutes() - logoutTimeout)){
         user = null;
         AsyncStorage.removeItem('vitlsUser');
       }
     } catch (e) {
       // console.log(e)
       // Restoring token failed
     }

     // After restoring token, we may need to validate it in production apps

     // This will switch to the App screen or Auth screen and this loading
     // screen will be unmounted and thrown away.
     dispatch({ type: 'RESTORE_USER', profile: user });
   };

   bootstrapAsync();
 }, []);

 const authContext = React.useMemo(
   () => ({
     signIn: async data => {
       // In a production app, we need to send some data (usually username, password) to server and get a token
       // We will also need to handle errors if sign in failed
       // After getting token, we need to persist the token using `AsyncStorage`
       // In the example, we'll use a dummy token
       //console.log("SIGNIN AUTHCONTEXT")
       //console.log(data);
        AsyncStorage.setItem('vitlsUser',JSON.stringify(data))
        dispatch({ type: 'SIGN_IN', profile: data });
     },
     signOut: async data => {
      // console.log('signout');
       AsyncStorage.removeItem('vitlsUser')
       AsyncStorage.removeItem('auth@aad')
       //dispatch({ type: 'SIGN_OUT' });
       window.location.replace('/logout')
     },
     signUp: async data => {
       // In a production app, we need to send user data to server and get a token
       // We will also need to handle errors if sign up failed
       // After getting token, we need to persist the token using `AsyncStorage`
       // In the example, we'll use a dummy token
       //console.log(data)
       dispatch({ type: 'SIGN_IN', profile: data });
     },
     updateUser: async data => {
       //console.log(data)
       AsyncStorage.setItem('vitlsUser',JSON.stringify(data))
       dispatch({ type: 'UPDATE', profile: data });
     },
     isSignout: state.isSignout,
     user: state.user,
   }),
   []
 );

 const logoutUserTimer = setTimeout(() => {
   authContext.signOut();
   console.log("Logging out due to inactivity.")
 },20*60*1000); //Logout after 20 minutes

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
       <MsalProvider instance={msalInstance}>
       <AuthContext.Provider value={authContext}>

        <View style={styles.container}>
          {Platform.OS === 'ios' && <StatusBar barStyle="dark-content" />}
          <NavigationContainer linking={LinkingConfiguration}>
            <Stack.Navigator >
            {state.isLoading ? (
                // We haven't finished checking for the token yet
                <Stack.Screen name="Splash" component={SplashScreen} />
              ) :
              state.user == null ? (
                <Stack.Screen options={{headerShown: false}} name="login" >
                  {props => <SignInScreen {...props} auth={authContext} />}
                </Stack.Screen>
              ) : (
                <Stack.Screen name="Root" >
                  {props => <BottomTabNavigator {...props}
                    navigation={props.navigation} user={state.user} instance={msalInstance} auth={authContext} logoutTimer={logoutUserTimer}/>}
                  </Stack.Screen>
              )
            }
            </Stack.Navigator>
          </NavigationContainer>
        </View>

      </AuthContext.Provider>
      </MsalProvider>
    );
  }
}