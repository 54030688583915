import * as WebBrowser from 'expo-web-browser';
import React, { useState, useEffect } from 'react';
import { Text, TouchableOpacity, View, TextInput} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { styles } from '../constants/styles';
import PatientRow from '../components/PatientRow';
import { RFPercentage } from "react-native-responsive-fontsize";
import DataChart from '../components/DataChart';
import { CSVLink } from "react-csv";
import { getVitalsPlotData } from '../hooks/getVitalsPlotData';
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, BaseProvider} from 'baseui';

import AzureFunc from '../hooks/azureFunc';
const azf = new AzureFunc();
const engine = new Styletron();


export default function PatientHistory({ user, navigation, route }) {

  const [patientID, setPatientID] = useState('');
  const [patientIDSelected, setPatientIDSelected] = useState(false);
  const [admission, setAdmission] = useState({});
  const [message, setMessage] = useState(''); 
  const [admissions, setAdmissions] = useState([]);  
  const [filteredAdmissions, setFilteredAdmissions] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [vitals, setVitals] = useState(null);
  const [plot, setPlot] = useState({label:'', thresh:{'high':null, 'low': null}, data:vitals});
  const [rawData, setRaw] = useState([]);
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [gtDate, setGTDate] = useState(new Date());
  const [ltDate, setLTDate] = useState(new Date());
  const [numOfVitals, setNumOfVitals] = useState(0);
  const [vitalsAlerts, setVitalsAlerts] = useState({atRisk: {hr:[],temp:[],spo2:[],resp:[]},
    unstable: {hr:[],temp:[],spo2:[],resp:[]}});

  async function getDischargedAdmissions(){
    let limit = 100; // Limit number of discharged admissions to obtain
    let dischargedAdmissionsResults = await azf.getDischargedAdmissions(
      user.selectedRegistration.admissionCenterId, user.selectedRegistration.wardId, limit);
    setAdmissions(dischargedAdmissionsResults.data)
    setFilteredAdmissions(dischargedAdmissionsResults.data)
  }

  useEffect(()=>{    
    getDischargedAdmissions()
  },[]);

  function filterAdmissionList(text){
    setPatientID(text)
    setFilteredAdmissions(admissions.filter(adm => adm.patient.id.includes(text)))
  }

  function clearPatient(){
    setPatientID("")
    setRaw([])
    setIsLoading(true)
    setNumOfVitals(0)
    setFilteredAdmissions(admissions)
  }

  function selectAdmission(admission){
    let admissionSelected = admission
    admissionSelected['admissionID'] = admissionSelected.id
    setAdmission(admissionSelected)
    setPatientID(admissionSelected.patient.id)
    setPatientIDSelected(true)
    setGTDate(new Date(admissionSelected.admitted))
    setFilteredAdmissions(admissions.filter(adm => adm.id == admissionSelected.id))
    console.log(admissionSelected)
    getVitalsPlotData(admissionSelected, new Date(admissionSelected.admitted), setGTDate, ltDate, setLTDate, setVitals, user.units,
      setMessage, setIsLoading, plot, setPlot, setRaw, setNumOfVitals, setVitalsAlerts);
  }

  const tempUnit = !user.units ? '\u2103' : '\u2109';
  const headers = [
    {label: "Time", key: "time"},
    {label: "SPO2(%)", key: "spo2"},
    {label: "HR(BPM)", key: "hr"},
    {label: "Respiration(BrPM)", key: "rr"},
    {label: `Temperature(${tempUnit})`, key: "temp"},
  ]

  return (
    <View style={styles.container}>
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
          <View style={[styles.col, {alignSelf: 'center', alignItems: 'center',width:'80%'}]}>
            <View style={styles.row}>
              <View style={[styles.formContainer, {width:'50%'}]}>
                <TextInput style={styles.formInput} placeholder='patient ID'
                  placeholderTextColor = "gray"
                  id="patientID" onChangeText={text => filterAdmissionList(text)}
                  value={patientID} autoCapitalize='none'/>
              </View>
              <TouchableOpacity style={styles.formBtn} title="clear" onPress={() => clearPatient()}>
                  <Text style={styles.formBtnText}>Clear</Text>
              </TouchableOpacity>
            </View>
          <View style={[styles.col, {minWidth: '100%',maxHeight: 400}]}>
            <Text style={styles.formBtnText}>Discharged Patients List</Text>
            <View style={styles.tableHeader}>
                <View style={styles.tableTextContainer}>
                  <Text  style={styles.tableHeaderTextSmall}>Patient ID</Text>
                </View>
                <View style={styles.tableTextContainer}>
                  <Text  style={styles.tableHeaderTextSmall}>Device ID</Text>
                </View>
                <View style={[styles.tableTextContainer]}>
                  <Text  style={styles.tableHeaderTextSmall}>Bed</Text>
                </View>
                <View style={[styles.tableTextContainer]}>
                  <Text  style={styles.tableHeaderTextSmall}>Room</Text>
                </View>
              </View>
            <ScrollView style={[{'width':'101%'}]}>
              {filteredAdmissions.map((device) =>
                <PatientRow key={device.id} device={device} navigation={navigation} selectAdmission={selectAdmission}/>
              )}
            </ScrollView>
          </View>
        </View>

        {(patientID) ?
          <StyletronProvider value={engine}>
          <BaseProvider theme={LightTheme}>
            <View style={styles.col}>
              {(isLoading) ?
                <View style={[styles.col, {alignItems:'center', justifyContent:'center', height:300}]}>
                  <Text style={styles.message}>{message}</Text>
                  <Text style={styles.message}>From {gtDate.toLocaleString()} to {ltDate.toLocaleString()}</Text>
                </View>
                :
                <View style={styles.col}>
                  <DataChart label={plot.label} data={plot.data} thresh={plot.thresh} 
                    admitted={new Date(admission.admitted).toISOString()} latestVitals={new Date(admission.discharged).toISOString()}/>
                </View>
              }
              <View style={[styles.row,{marginBottom:20}]}>
                <TouchableOpacity style={[styles.categoryButton, {backgroundColor: plot.label == "BPM" ? '#ededed' : 'white'}]}
                  onPress={() => vitals && setPlot({label:"BPM", thresh:admission.thresholds.hr, data:vitals.hr})}>
                    <Text style={styles.formBtnText}>Heart Rate</Text>
                </TouchableOpacity>

                <TouchableOpacity style={[styles.categoryButton, {backgroundColor: plot.label == "%SPO2" ? '#ededed' : 'white'}]}
                  onPress={() => vitals && setPlot({label:"%SPO2", thresh:admission.thresholds.spo2, data:vitals.spo2})}>
                    <Text style={styles.formBtnText}>SATS</Text>
                </TouchableOpacity>

                <TouchableOpacity style={[styles.categoryButton, {backgroundColor: plot.label == "BrPM" ? '#ededed' : 'white'}]}
                  onPress={() => vitals && setPlot({label:"BrPM", thresh:admission.thresholds.rr, data:vitals.resp})}>
                    <Text style={styles.formBtnText}>Respiration</Text>
                </TouchableOpacity>

                <TouchableOpacity style={[styles.categoryButton, {backgroundColor: plot.label == `Temp ${tempUnit}` ? '#ededed' : 'white'}]}
                  onPress={() => vitals && setPlot({label:`Temp ${tempUnit}`, thresh:admission.thresholds.temp,  data:vitals.temp})}>
                    <Text style={styles.formBtnText}>Temperature</Text>
                </TouchableOpacity>
              </View>
            </View>

            <View style={[styles.row,{marginBottom:20}]}>
              <View style={styles.card}>
                <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
                  <Text style={styles.cardHeaderValue}>Download Vitals</Text>
                </View>
                <Text style={styles.cardHeaderLabel}>Vitals Obtained:</Text>
                <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{numOfVitals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                <View style={[styles.formBtn,{backgroundColor:'white'}]}>
                  <CSVLink style={{color:'gray', fontSize: RFPercentage(2), alignSelf: 'center',
                      textDecoration: 'none'}} data={rawData} headers={headers}
                      filename={(admission.patient ? admission.patient.id : "" + '_' + gtDate.toISOString().replace(':','-').split('.')[0] + 'Z_to_' + ltDate.toISOString().replace(':','-').split('.')[0] + 'Z.csv').replace(':','-')}>
                    Download
                  </CSVLink>
                </View>
              </View>
            </View>
            </BaseProvider>
          </StyletronProvider>
          :
          null
          }

        <View style={[styles.col]}>
          <Text style={styles.errorMessage}>{message}</Text>
        </View>

      </ScrollView>
    </View>
  );
}
