import AzureFunc from '../hooks/azureFunc';
const azf = new AzureFunc();
import localforage from 'localforage';

function checkVitals(admission, hr, rr, temp, spo2, deviceTime, alert, vitalsAlerts){

    if(hr < admission.c_heart_rate_lower_limit ||
      hr > admission.c_heart_rate_upper_limit){
      vitalsAlerts.unstable.hr.push({time:deviceTime, value:hr})
    }else if (hr < admission.c_heart_rate_lower_limit * 1.1 ||
       hr > admission.c_heart_rate_upper_limit * 0.9){
      vitalsAlerts.atRisk.hr.push({time:deviceTime, value:hr})
    }

    if(spo2 < admission.c_pulse_ox_lower_limit){
      vitalsAlerts.unstable.spo2.push({time:deviceTime, value:spo2})
    }else if (spo2 < admission.c_pulse_ox_lower_limit * 1.02){
      vitalsAlerts.atRisk.spo2.push({time:deviceTime, value:spo2})
    }

    if(rr < admission.c_respiration_rate_lower_limit ||
      rr > admission.c_respiration_rate_upper_limit * 1.1){
      vitalsAlerts.unstable.resp.push({time:deviceTime, value:rr})
    }else if (rr < admission.c_respiration_rate_lower_limit ||
      rr > admission.c_respiration_rate_upper_limit * 0.9){
      vitalsAlerts.atRisk.resp.push({time:deviceTime, value:rr})
    }

    if(temp < admission.c_temperature_lower_limit ||
      temp > admission.c_temperature_upper_limit){
      vitalsAlerts.unstable.temp.push({time:deviceTime, value:temp})
    }else if (temp < admission.c_temperature_lower_limit + 0.5 ||
      temp > admission.c_temperature_upper_limit - 0.5){
      vitalsAlerts.atRisk.temp.push({time:deviceTime, value:temp})
    }

    if(alert.includes('Device')){
      vitalsAlerts.device.device.push({time:deviceTime})
    }
    if(alert.includes('Battery')){
      vitalsAlerts.device.battery.push({time:deviceTime})
    }
    if(alert.includes('Skin')){
      vitalsAlerts.device.skin.push({time:deviceTime})
    }
    if(alert.includes('Signal')){
      vitalsAlerts.device.signal.push({time:deviceTime})
    }

    return vitalsAlerts

}

function checkAlertByte(tegoMessage){
  // Example message
  // [0]Code, [1]Year after 2000, [2]Month, [3]Day, [4]Hour, [5]Minute, [6]Second, [7]RespRate, [8]Temp MSB, [9]Temp LSB,
  // [10]SPO2 MSB, [11]SPO2 LSB, [12]BatV MSB, [13]BatV LSB, [14]HR MSB, [15]HR LSB, [16]HRV, [17]Device Alert, [18]Reset Source
  // 04140c090c372400000003ca012502f800
  let alertMessage = ''
  if(tegoMessage.length >= 18*2){
    let alert = tegoMessage.slice(34,36)
    const alertBinary = parseInt(alert, 16).toString(2).padStart(8, '0')

    if(alertBinary[4] == '1'){
      alertMessage += 'Device failure, '
    }
    if(alertBinary[5] == '1'){
      alertMessage += 'Low Battery, '
    }
    if(alertBinary[6] == '1'){
      alertMessage += 'Skin Contact, '
    }
    if(alertBinary[7] == '1'){
      alertMessage += 'Low Signal, '
    }
  }
  return alertMessage
}

async function checkCachedVitals(admissionID){
  return localforage.getItem(admissionID).then(function(value) {
    // This code runs once the value has been loaded
    // from the offline store.
      //console.log(value);
      return value
    }).catch(function(err) {
      // This code runs if there were any errors
      console.log(err);
      return null
    });
}

export async function getVitalsPlotData(data, gtDate, setGTDate, ltDate,
  setLTDate, setVitals, userOptions, setMessage, setIsLoading, plot, setPlot,
  setRaw, setNumOfVitals, setVitalsAlerts){
    const limit = 5000;

    //console.log(ltDate)
    //console.log(gtDate)
    var responseData = []
    var fromDate = gtDate
    var toDate = ltDate



    // Check cached data
    let cachedData = await checkCachedVitals(data.admissionID)
    //console.log(cachedData)
    if (cachedData){
      //console.log("Found some old data")
      //console.log(cachedData)
      fromDate = new Date(cachedData[cachedData.length-1].time)
      fromDate.setSeconds(fromDate.getSeconds() + 1);
      //console.log("Setting new fromDate to " + fromDate + " and toDate is " + toDate)
      responseData = cachedData
      //console.log(responseData)
    }

    while (true){
      //console.log("from: " + fromDate + " to: " + toDate)

      let response = await azf.getVitals(data.deviceId, fromDate.getTime(), toDate.getTime(), limit)
      //console.log(response)
      if(response.data.length){
        responseData.push(...response.data)
        // Check if limit reached, and look for more if true
        if(response.data.length < limit){
          break
        }else {
          fromDate =  new Date(response.data[response.data.length-1].time)
          setNumOfVitals(responseData.length)
          setGTDate(fromDate)
        }
      }else {
        break
      }
    }

    setNumOfVitals(responseData.length)
    //console.log(responseData)

    if(responseData.length){
      localforage.setItem(data.admissionID, responseData).then(function(value) {
          // This will output `1`.
          //console.log(value[0]);
      }).catch(function(err) {
          // This code runs if there were any errors
          console.log(err);
      });

      var vitalsData = {hr:[],temp:[],spo2:[],resp:[],alert:[]};
      var vitalsAlerts = {atRisk: {hr:[],temp:[],spo2:[],resp:[]},
        unstable: {hr:[],temp:[],spo2:[],resp:[]},
        device: {signal:[], skin:[], battery:[], device:[]}};
      var rawVitalsData = [];
      responseData.map((value,idx) => {
        let spo2 = 0;
        let hr = 0;
        let rr = 0;
        let temp = 0;
        const timeValue = value.time;
        const deviceTime = new Date(value.time).toLocaleString();

        if((value.spo2  > 100) || (value.hr > 240)){
          hr = Math.round(value.hr/10) ;
          rr = value.rr;
          temp = value.temperature/100;
          spo2 = Math.round(value.spo2/10);
        } else {
          hr = value.hr ;
          rr = value.rr;
          temp = value.temperature;
          spo2 = Math.round(value.spo2);
        }
         
         let alert = ''
         /*
         if(value.message){
           alert = checkAlertByte(value.message)
         }
         */

         //vitalsAlerts = checkVitals(data, hr, rr, temp, spo2, deviceTime, alert, vitalsAlerts);

         if(!userOptions){
           temp = parseFloat(temp.toFixed(1))
         } else {
           temp = parseFloat(((temp * (9/5)) + 32).toFixed(1))
         }

         vitalsData.hr.push({idx, timeValue, value: hr, date: deviceTime, alert});
         vitalsData.temp.push({idx, timeValue, value: temp, date: deviceTime, alert});
         vitalsData.spo2.push({idx, timeValue, value: spo2, date: deviceTime, alert});
         vitalsData.resp.push({idx, timeValue, value: rr, date: deviceTime, alert});

       rawVitalsData.push({time: deviceTime, temp, hr, rr, spo2, alert})
      })
      //console.log(vitalsData)
      setVitals(vitalsData)
      setRaw(rawVitalsData)
      setVitalsAlerts(vitalsAlerts)

      const tempUnit = !userOptions ? '\u2103' : '\u2109';
      if (!plot.data || plot.label == 'BPM'){
        setPlot({label:'BPM', thresh: data.thresholds.hr, data:vitalsData.hr})
      }else if (plot.label == "%SPO2"){
        setPlot({label:'%SPO2', thresh: data.thresholds.spo2, data:vitalsData.spo2})
      }else if (plot.label == "BrPM"){
        setPlot({label:'BrPM', thresh: data.thresholds.rr, data:vitalsData.resp})
      }else if (plot.label == `Temp ${tempUnit}` ){
        setPlot({label:tempUnit, thresh: data.thresholds.temp, data:vitalsData.temp})
      }

      setIsLoading(false)
    }else if (responseData){
      // No data found for device
      setMessage("No vitals data found for device.")
    }else {
      // Probably logged out (response.code == 'kSessionExpired')
      setMessage("Session expired, please login again.")
    }
}
