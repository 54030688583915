import { Ionicons } from '@expo/vector-icons';
import * as WebBrowser from 'expo-web-browser';
import React, { useState, useEffect } from 'react';
import { Text, View, TouchableOpacity, TextInput } from 'react-native';
import { RectButton, ScrollView } from 'react-native-gesture-handler';
import { styles } from '../constants/styles';
import DataChart from '../components/DataChart';
import PatientInfoCardEditable from '../components/PatientInfoCardEditable';
import PatientInfoCard from '../components/PatientInfoCard';
import VitalsThresholds from '../components/VitalsThresholds';
import { CSVLink } from "react-csv";
import { getVitalsPlotData } from '../hooks/getVitalsPlotData';
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import VitalsCardPatientView from '../components/VitalsCardPatientView';
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, BaseProvider} from 'baseui';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, FocusOnce } from "baseui/modal";
import localforage from 'localforage';

import AzureFunc from '../hooks/azureFunc';

const azf = new AzureFunc();

const engine = new Styletron();

export default function PatientData({auth, user, navigation, route }) {
  const [data, setData] = useState(route.params ? route.params.data : {});
  const [isLoading, setIsLoading] = useState(true);
  const [vitals, setVitals] = useState(null);
  const [latestVitals, setLatestVitals] = useState({...data, time:data ? new Date(data.time) : new Date("1970")});
  const [message, setMessage] = useState("Loading...");
  const [fetchData, setFetchData] = useState(true);
  const [plot, setPlot] = useState({label:'', thresh:{'high':null, 'low': null}, data:vitals});
  const [rawData, setRaw] = useState([]);
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);
  // Set initial greater than date to be 1 day ago, and less than date to be current date
  const [gtDate, setGTDate] = useState(data ? new Date(data.admitted) : new Date());
  const [ltDate, setLTDate] = useState(new Date());
  const [numOfVitals, setNumOfVitals] = useState('');
  const [vitalsAlerts, setVitalsAlerts] = useState({atRisk: {hr:[],temp:[],spo2:[],resp:[]},
    unstable: {hr:[],temp:[],spo2:[],resp:[]}});
  const [modal, setModal] = useState({header:'', priority: 'unstable', key:'hr', isOpen:false});
  const [viewPatientInfoModal, setViewPatientInfoModal] = useState(false);
  const [viewDischargeModal, setViewDischargeModal] = useState(false);
  const admittedDate = data.admitted ? new Date(data.admitted) : new Date();

  function checkCurrentAdmission(){
    localforage.getItem("vitls-current-admission")
    .then((item) => {
      if(item){
        getNotes(item.admissionCenterId, item.admissionID);
        setGTDate(new Date(item.admitted))
        setLatestVitals({...item, time:new Date(item.time)})
        setData(item)
        console.log(item)
        getVitalsPlotData(item, new Date(item.admitted), setGTDate, ltDate, setLTDate, setVitals, user.units,
          setMessage, setIsLoading, plot, setPlot, setRaw, setNumOfVitals, setVitalsAlerts);
      }
    })
    .catch((e) => console.log(e))
  }
  
  async function getNotes(admissionCenterId, admissionID){
    const oldNotes = await azf.getNotes(admissionCenterId, admissionID);
    setNotes(oldNotes.data);
  }

  async function addNote(){
    if(note){
      const noteInfo = {
        "created": new Date().toISOString(),
        "admissionCenterId": data.admissionCenterId,
        "admissionId": data.admissionID,
        "patientId": data.patient.id,
        "note": note,
        "creator": user.username // userId
      }
      const response = await azf.addNote(noteInfo);
      getNotes(data.admissionCenterId, data.admissionID)
      setNote('')
    }
  }

  async function discharge(){
    await azf.dischargePatient(data)
    navigation.jumpTo('Dashboard')
  }

  useEffect(() => {
    if (!data.patient){
      checkCurrentAdmission()
    } else {
      localforage.setItem("vitls-current-admission", data)
    }
    if(data.admissionID){
      getNotes(data.admissionCenterId, data.admissionID);
    }
    if(data.deviceId){
      getVitalsPlotData(data, gtDate, setGTDate, ltDate, setLTDate, setVitals, user.units,
        setMessage, setIsLoading, plot, setPlot, setRaw, setNumOfVitals, setVitalsAlerts);
      }
  },[]);

  useEffect(() => {
    if(data.patient){
      updateLatestVitalsCard()
    }
  },[fetchData])

  var fetchDataTimer = setTimeout(() => {
    setFetchData(!fetchData);
  },5000); //Update data every 10 seconds

  async function updateLatestVitalsCard(){
    const vitalsUpdate = await azf.getLatestVitals([`"${data.deviceId}"`])
    //console.log(vitalsUpdate)
    clearTimeout(fetchDataTimer)

    if(vitalsUpdate.data[0].spo2 > 100){
      vitalsUpdate.data[0].spo2 = vitalsUpdate.data[0].spo2/10
      vitalsUpdate.data[0].hr = vitalsUpdate.data[0].hr/10
      vitalsUpdate.data[0].temperature = vitalsUpdate.data[0].temperature/100
      vitalsUpdate.data[0].battery = vitalsUpdate.data[0].battery/100
    }
    
    setLatestVitals({...latestVitals, ...vitalsUpdate.data[0], time:new Date(vitalsUpdate.data[0].time)})
  }

  async function clearCache(){
    localforage.removeItem(data.admissionID)
  }

  function openVitalsLog(vital, priority){
    switch(vital){
      case 'hr':
        setModal({header:'Heart Rate Alert Log',
          priority: priority,
          key: 'hr',
          isOpen: true})
        break;
      case 'spo2':
        setModal({header:'Oxygen Saturation Alert Log',
          priority: priority,
          key: 'spo2',
          isOpen: true})
        break;
      case 'resp':
        setModal({header:'Respiration Rate Alert Log',
          priority: priority,
          key: 'resp',
          isOpen: true})
        break;
      case 'temp':
        setModal({header:'Temperature Alert Log',
          priority: priority,
          key: 'temp',
          isOpen: true})
        break;
      case 'skin':
        setModal({header:'Poor Skin Contact Alert Log',
          priority: priority,
          key: 'skin',
          isOpen: true})
        break;
      case 'signal':
        setModal({header:'Low Signal Alert Log',
          priority: priority,
          key: 'signal',
          isOpen: true})
        break;
      case 'battery':
        setModal({header:'Low Battery Alert Log',
          priority: priority,
          key: 'battery',
          isOpen: true})
        break;
      case 'device':
        setModal({header:'Device Failure Alert Log',
          priority: priority,
          key: 'device',
          isOpen: true})
        break;
    }
  }

  const tempUnit = !user.units ? '\u2103' : '\u2109';
  const headers = [
    {label: "Time", key: "time"},
    {label: "SPO2(%)", key: "spo2"},
    {label: "HR(BPM)", key: "hr"},
    {label: "Respiration(BrPM)", key: "rr"},
    {label: `Temperature(${tempUnit})`, key: "temp"},
  ]

  const modalHeader = [
    {label: "Time", key: "time"},
    {label: "Value", key: "value"}
  ]

  return (
    <View style={styles.container}>
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
      {data.patient ?
        <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
        <View style={[styles.row, {justifyContent:'center'}]}>
          <PatientInfoCard data={data} user={user} setViewPatientInfoModal={setViewPatientInfoModal} latestVitals={latestVitals}/>
          
          <Modal onClose={() => setViewPatientInfoModal(false)} isOpen={viewPatientInfoModal} unstable_ModalBackdropScroll={true}>
                <FocusOnce>
                    <ModalHeader>Update Patient Info</ModalHeader>
                </FocusOnce>
                <ModalBody>
                    <ScrollView >
                      <PatientInfoCardEditable data={data} setData={setData} user={user} setViewPatientInfoModal={setViewPatientInfoModal}/>
                    </ScrollView>
                </ModalBody>
            </Modal>

          <VitalsThresholds data={data} user={user}/>
        </View>

        <View style={styles.row}>
          <View style={[styles.col,styles.notesCard]}>
            <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
              <Text style={styles.cardHeaderValue}>Notes</Text>
            </View>

            {notes.map((note) =>
              <View key={note.created}>
                <View style={styles.row}>
                  <Text style={styles.cardHeaderLabel}>{new Date(note.created).toLocaleString('en-US',{hour12:false,timeZoneName:'short'})}</Text>
                  <Text style={styles.cardHeaderLabel}>{' -- ' + note.creator}</Text>
                </View>
                <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{note.note}</Text>
              </View>
            )}

            <View style={[styles.formContainerFull,{width:'100%'}]}>
              <TextInput style={[styles.formInput,{marginTop: 40,height:140,width:'100%'}]} placeholder='note'
                placeholderTextColor = "gray" multiline={true}
                id="note" onChangeText={text => setNote(text)}
                value={note} autoCapitalize='none'/>
            </View>
            <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => addNote()}>
                <Text style={styles.formBtnText}>Add Note</Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={styles.row}>
          <View style={[styles.col,styles.notesCard]}>
            <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
              <Text style={styles.cardHeaderValue}>Vitals Alerts</Text>
            </View>
            {(!isLoading) &&
            <View style={styles.row}>
              <Modal onClose={() => setModal({...modal, isOpen:false})} isOpen={modal.isOpen} unstable_ModalBackdropScroll={true}>
                <FocusOnce>
                  <ModalHeader>{modal.header}</ModalHeader>
                </FocusOnce>
                <ModalBody>
                  <ScrollView style={{maxHeight:200}}>
                    {vitalsAlerts[modal.priority][modal.key].map((alert) =>
                      <View key={alert.time}>
                        <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{alert.time + '--' + alert.value}</Text>
                      </View>
                    )}
                  </ScrollView>
                </ModalBody>
                <ModalFooter>
                  <View style={[styles.formBtnSmall,{backgroundColor:'white'}]}>
                    <CSVLink style={{color:'gray', fontSize: RFPercentage(2), alignSelf: 'center',
                        textDecoration: 'none', margin:5}} data={vitalsAlerts[modal.priority][modal.key]} headers={modalHeader}
                        filename={(data.patient ? data.patient.id : "" + '_' + modal.key + '_alert_log' + '.csv')}>
                      Download
                    </CSVLink>
                  </View>
                  <TouchableOpacity style={[styles.formBtnSmall,{backgroundColor:'white'}]} onPress={() => setModal({...modal, isOpen:false})}>
                      <Text style={styles.formBtnText}>Close</Text>
                  </TouchableOpacity>
                </ModalFooter>
              </Modal>
              <View style={[styles.col,{width:'50%'}]}>
                <Text style={[styles.cardHeaderLabel,{alignSelf:'center'}]}>Unstable</Text>
                <TouchableOpacity onPress={() => openVitalsLog('hr','unstable')}>
                  <Text style={styles.cardHeaderLabel}>Heart Rate:</Text>
                  <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{Math.round((vitalsAlerts.unstable.hr.length/numOfVitals)*100).toString() + '%'}</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => openVitalsLog('spo2','unstable')}>
                  <Text style={styles.cardHeaderLabel}>%SPO<Text style={styles.cardUnitSub}>2</Text>:</Text>
                  <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{Math.round((vitalsAlerts.unstable.spo2.length/numOfVitals)*100).toString() + '%'}</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => openVitalsLog('resp','unstable')}>
                  <Text style={styles.cardHeaderLabel}>Respiration Rate:</Text>
                  <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{Math.round((vitalsAlerts.unstable.resp.length/numOfVitals)*100).toString() + '%'}</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => openVitalsLog('temp','unstable')}>
                  <Text style={styles.cardHeaderLabel}>Temperature:</Text>
                  <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{Math.round((vitalsAlerts.unstable.temp.length/numOfVitals)*100).toString() + '%'}</Text>
                </TouchableOpacity>
              </View>
              <View style={[styles.col,{width:'50%'}]}>
                <Text style={[styles.cardHeaderLabel,{alignSelf:'center'}]}>At-Risk</Text>
                <TouchableOpacity onPress={() => openVitalsLog('hr','atRisk')}>
                  <Text style={styles.cardHeaderLabel}>Heart Rate:</Text>
                  <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{Math.round((vitalsAlerts.atRisk.hr.length/numOfVitals)*100).toString() + '%'}</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => openVitalsLog('spo2','atRisk')}>
                  <Text style={styles.cardHeaderLabel}>%SPO<Text style={styles.cardUnitSub}>2</Text>:</Text>
                  <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{Math.round((vitalsAlerts.atRisk.spo2.length/numOfVitals)*100).toString() + '%'}</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => openVitalsLog('resp','atRisk')}>
                  <Text style={styles.cardHeaderLabel}>Respiration Rate:</Text>
                  <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{Math.round((vitalsAlerts.atRisk.resp.length/numOfVitals)*100).toString() + '%'}</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => openVitalsLog('temp','atRisk')}>
                  <Text style={styles.cardHeaderLabel}>Temperature:</Text>
                  <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{Math.round((vitalsAlerts.atRisk.temp.length/numOfVitals)*100).toString() + '%'}</Text>
                </TouchableOpacity>
              </View>
            </View>
          }
            <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
              <Text style={styles.cardHeaderValue}>Device Alerts</Text>
            </View>
            {(!isLoading) &&
              <View style={[styles.row, {justifyContent:'space-around'}]}>
                <TouchableOpacity onPress={() => openVitalsLog('skin','device')}>
                  <Text style={styles.cardHeaderLabel}>Skin Contact:</Text>
                  <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{Math.round((vitalsAlerts.device.skin.length/numOfVitals)*100).toString() + '%'}</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => openVitalsLog('signal','device')}>
                  <Text style={styles.cardHeaderLabel}>Low Signal</Text>
                  <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{Math.round((vitalsAlerts.device.signal.length/numOfVitals)*100).toString() + '%'}</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => openVitalsLog('battery','device')}>
                  <Text style={styles.cardHeaderLabel}>Low Battery</Text>
                  <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{Math.round((vitalsAlerts.device.battery.length/numOfVitals)*100).toString() + '%'}</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => openVitalsLog('device','device')}>
                  <Text style={styles.cardHeaderLabel}>Device Failure</Text>
                  <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{Math.round((vitalsAlerts.device.device.length/numOfVitals)*100).toString() + '%'}</Text>
                </TouchableOpacity>
              </View>
            }
          </View>
        </View>



        <View style={styles.col}>

          {(isLoading) ?
            <View style={[styles.col, {alignItems:'center', justifyContent:'center', height:300}]}>
              <Text style={styles.message}>{message}</Text>
              <Text style={styles.message}>From {gtDate.toLocaleString()} to {ltDate.toLocaleString()}</Text>
            </View>
            :
            <View style={styles.col}>
              <VitalsCardPatientView data={latestVitals} options={user.units}/>
              <DataChart label={plot.label} data={plot.data} thresh={plot.thresh} 
                admitted={admittedDate.toISOString()} latestVitals={latestVitals.time.toISOString()}/>
            </View>
          }
            <View style={[styles.row,{marginBottom:20}]}>
              <TouchableOpacity style={[styles.categoryButton, {backgroundColor: plot.label == "BPM" ? '#ededed' : 'white'}]}
                onPress={() => vitals && setPlot({label:"BPM", thresh:data.thresholds.hr, data:vitals.hr})}>
                  <Text style={styles.formBtnText}>Heart Rate</Text>
              </TouchableOpacity>

              <TouchableOpacity style={[styles.categoryButton, {backgroundColor: plot.label == "%SPO2" ? '#ededed' : 'white'}]}
                onPress={() => vitals && setPlot({label:"%SPO2", thresh:data.thresholds.spo2, data:vitals.spo2})}>
                  <Text style={styles.formBtnText}>SATS</Text>
              </TouchableOpacity>

              <TouchableOpacity style={[styles.categoryButton, {backgroundColor: plot.label == "BrPM" ? '#ededed' : 'white'}]}
                onPress={() => vitals && setPlot({label:"BrPM", thresh:data.thresholds.rr, data:vitals.resp})}>
                  <Text style={styles.formBtnText}>Respiration</Text>
              </TouchableOpacity>

              <TouchableOpacity style={[styles.categoryButton, {backgroundColor: plot.label == `Temp ${tempUnit}` ? '#ededed' : 'white'}]}
                onPress={() => vitals && setPlot({label:`Temp ${tempUnit}`, thresh:data.thresholds.temp,  data:vitals.temp})}>
                  <Text style={styles.formBtnText}>Temperature</Text>
              </TouchableOpacity>
            </View>
          </View>

          <View style={[styles.row,{marginBottom:20}]}>
            <View style={styles.card}>
              <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
                <Text style={styles.cardHeaderValue}>Download Vitals</Text>
              </View>
              <Text style={styles.cardHeaderLabel}>Vitals Obtained:</Text>
              <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{numOfVitals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
              <View style={[styles.formBtn,{backgroundColor:'white'}]}>
                <CSVLink style={{color:'gray', fontSize: RFPercentage(2), alignSelf: 'center',
                    textDecoration: 'none'}} data={rawData} headers={headers}
                    filename={(data.patient ? data.patient.id : "" + '_' + gtDate.toISOString().replace(':','-').split('.')[0] + 'Z_to_' + ltDate.toISOString().replace(':','-').split('.')[0] + 'Z.csv').replace(':','-')}>
                  Download
                </CSVLink>
              </View>
              <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => clearCache()}>
                  <Text style={styles.formBtnText}>Clear Vitals</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={[styles.row,{marginBottom:20}]}>
            <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white', width:'25%'}]} onPress={() => setViewDischargeModal(true)}>
                  <Text style={styles.formBtnText}>Discharge Patient</Text>
            </TouchableOpacity>
          </View>
          <Modal onClose={() => setViewDischargeModal(false)} isOpen={viewDischargeModal} unstable_ModalBackdropScroll={true}>
              <FocusOnce>
                <ModalHeader>Discharge Patient</ModalHeader>
              </FocusOnce>
              <ModalBody>
              <Text style={styles.cardHeaderValue}>Please, confirm discharge patient. Patient data will not be available on Monitor Dashboard after discharge.</Text>
              <Text style={styles.cardHeaderValue}> Request data exports from Vitls Support.</Text>
              </ModalBody>
              <ModalFooter>
              <View style={[styles.row,{marginBottom:20}]}>
                <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white', width:'40%'}]} onPress={() => discharge()}>
                      <Text style={styles.formBtnText}>Discharge</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white', width:'40%'}]} onPress={() => setViewDischargeModal(false)}>
                      <Text style={styles.formBtnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
              </ModalFooter>
            </Modal>
          </BaseProvider>
          </StyletronProvider>

        :
        <View style={styles.col}>
          <Text style={styles.message}>Please select patient from Dashboard to view data.</Text>
        </View>
      }

    </ScrollView>
    </View>
  );
}