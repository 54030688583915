const tintColor = '#2f95dc';

export default {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  vitlsPink: '#f49ac1',
  vitlsPurple: '#a085be',
  vitlsOrange: '#faae81',
  vitlsGreen: '#83ca9c',
  vitlsBlue: '#7ca6d8',
  vitlsRed: '#c90000',
  vitlsGray: '#6f6f6f',
};
