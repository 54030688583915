export default class AzureFunc {

    constructor(){
      this.baseURL = "/api/" // production for linked api
    }
  
    async request(method, endpoint, filter, data, accessToken=''){
      let response = null
      await fetch(this.baseURL + endpoint + filter, {
         method,
         body: data && JSON.stringify(data)
       })
       .then((resp) => {
         response = resp.json()
       })
       .catch((error) => {
         console.log(error)
         return response
       })
       return response
    }

    login(){

    }

    logout(){

    }
    
    signup(){

    }

    insertUserInfo(userInfo){
        // Updated user defualt settings
        return this.request('POST', 'insertUserInfo', '', userInfo)
    }

    updateUserInfo(userInfo){
        // Updated user defualt settings
        return this.request('POST', 'updateUserInfo', '', userInfo)
    }

    getUserInfo(userId, username){
        // Which admission center and wards can the user access?
        return this.request('POST', 'getUserInfo', '', {userId, username})
    }

    getAllUserInfo(){
        // Get all user info for user lookup
        return this.request('POST', 'getAllUserInfo', '', {})
    }

    getActiveAdmissions(admissionCenterId, wardId, limit){
        // Get all patient admissions currently active for selected admission center and ward 
        return this.request('POST', 'getActiveAdmissions', '', {admissionCenterId, wardId, limit})
    }

    getDischargedAdmissions(admissionCenterId, wardId, limit){
        // Get all patient admissions currently active for selected admission center and ward 
        return this.request('POST', 'getDischargedAdmissions', '', {admissionCenterId, wardId, limit})
    }

    getDevice(deviceId){
        return this.request('POST', 'getDevice', '', {deviceId})
    }

    getLatestVitals(deviceIds){
        // Get latest vitalsign for all admitted patients for dashboard
        return this.request('POST', 'getLatestVitals', '', {deviceIds})
    }

    getVitals(deviceId, startTime, endTime, limit){
        // Get all historic vtials for patient
        return this.request('POST', 'getVitals', '', {deviceId, startTime, endTime, limit})

    }

    createPatient(patientInfo){
        return this.request('POST', 'insertPatient', '', patientInfo)
    }

    getPatient(admissionCenterId, patientId){
        let info = {admissionCenterId, patientId}
        console.log(info)
        return this.request('POST', 'getPatient', '', info)
    }

    admitPatient(admCentId, wardId, deviceId, patientInfo, admissionInfo){
        // Admit new patient 
        const admissionRecord = {
            "admitted": new Date().toISOString(),
            "discharged": null,
            "updated": new Date().toISOString(),
            "deviceId": deviceId,
            "patient": patientInfo,
            "admissionCenterId": admCentId,
            "wardId": wardId,
            "room": admissionInfo.room,
            "bed": admissionInfo.bed,
            "diagnosis": admissionInfo.diagnosis,
            "thresholds": {'temp':{'high':40.0,'low':34.0},
                            'hr':{'high':200,'low':40},
                            'rr':{'high':40,'low':5},
                            'spo2':{'high':null,'low':75},
                            'hrv':{'high':200,'low':50}
                        }
        }

        return this.request('POST', 'insertAdmission', '', admissionRecord)

    }

    dischargePatient(patientInfo){
        patientInfo.discharged = new Date().toISOString();
        patientInfo.updated = new Date().toISOString();
        return this.request('POST', 'updateAdmission', '', patientInfo)
    }

    updateAdmission(updatedAdmission){
        // Update admission info, thresholds, discharge
        return this.request('POST', 'updateAdmission', '', updatedAdmission)
    }

    getPatientVitals(){
        // Get all vitalsigns for patient admission
        return this.request('POST', 'getVitals', '', newNote)
    }

    addNote(newNote){
        // Add a note for a patient admission
        return this.request('POST', 'insertNote', '', newNote)
    }

    getNotes(admissionCenterId,admissionId){
        // Get all notes for admission
        return this.request('POST', 'getNotes', '', {admissionCenterId,admissionId})
    }

    deleteNote(){
        // Delete note if creator or admin
        return this.request('POST', 'deleteNote', '', deletedNote)

    }

    updateNote(){
        // Update note if creator or admin
        return this.request('POST', 'insertNote', '', updatedNote)

    }

    getWard(admissionCenterId){
        return this.request('POST', 'getWards', '', {admissionCenterId})
    }

    async enrollUser(user, admissionCenterId, admissionCenterName, wardId, wardName){
        let existingRegistrations = user.registrations
        let wardsInfo = [{'id': wardId, 'name': wardName}]
        if(!existingRegistrations.length){
            // This is first registration for user
            user.registrations = [ {admissionCenterId, admissionCenterName, 'wards': wardsInfo}]
            user.selectedRegistration = {admissionCenterId, admissionCenterName, wardId, wardName}
            return this.updateUserInfo(user)
        } else {
            let sameRegistrations = existingRegistrations.filter(registration => registration.admissionCenterId == admissionCenterId)
            if(sameRegistrations.length){
                // Same registration exists for user, add new ward if not exist
                let wardExists = sameRegistrations[0].wards.filter(ward => ward.id === wardId)
                if(wardExists.length){
                    // ward exists, so don't need to do anything
                    return {"enrolled":true}
                } else {
                    // ward does not exists, add it to wards info list of admission
                    let existingWards = sameRegistrations[0].wards
                    existingWards.push(wardsInfo[0])
                    let updatedRegistration = sameRegistrations[0]
                    updatedRegistration.wards = existingWards
                    user.registrations.map(registration => registration.admissionCenterId == admissionCenterId ? updatedRegistration : registration)                    
                }
            } else {
                // Same registration does not exist, add it fresh with ward
                user.registrations.push({admissionCenterId,  admissionCenterName, 'wards': wardsInfo})
            }
        }
        return await this.updateUserInfo(user)
    }

    async unEnrollFromAdmissionCenter(user, admissionCenterId){
        let updatedRegistrations = user.registrations.filter(registration => registration.admissionCenterId != admissionCenterId)
        user.registrations = updatedRegistrations
        return await this.updateUserInfo(user)
    }

    async unEnrollFromWard(user, admissionCenterId, wardId){
        let registrationToUpdate = user.registrations.filter(registration => registration.admissionCenterId == admissionCenterId)
        if(!registrationToUpdate.length){
            // user is not registerd to admission center to remove from ward
            return {"notEnrolled": true}
        } else {
            // expecting no multiple admission centers with same ID
            let updatedRegistration = registrationToUpdate[0]
            updatedRegistration.wards = updatedRegistration.wards.filter(ward => ward.id != wardId)
            console.log(updatedRegistration)
            user.registrations.map(registration => registration.admissionCenterId == admissionCenterId ? updatedRegistration : registration)
        }
        user.selectedRegistration = {}
        return await this.updateUserInfo(user)
    }

    deleteUser(user) {
        return this.request('POST', 'deleteUserInfo', '', {id:user.id, role:user.role})
    }

    addDevice(deviceInfo) {
        return this.request('POST', 'insertDevice', '', deviceInfo)
    }
}
