import { styles } from '../constants/styles';
import * as React from 'react';
import { Text, View } from 'react-native';

export default function CategoryHeader(props) {
  return (
    <View style={[styles.row, styles.categoryHeader]}>
      <View style={[styles.categoryHeaderIcon, props.categoryIcon]}></View>
      <Text style={styles.categoryHeaderText}>{props.value} of {props.total}</Text>
      <Text style={styles.categoryHeaderText}>{props.category}</Text>
    </View>
  );
}
