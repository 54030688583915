import { Platform, StyleSheet, Dimensions } from 'react-native';
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import Colors from './Colors';
const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").width;
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingTop: 10,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent:'center'
  },
  col: {
    flexDirection: 'column',
  },
  logoContainer: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  logo: {
    width: 200,
    height: 160,
    resizeMode: 'contain',
    marginTop: 3,
  },
  alarmSymbol: {
    width: 50,
    height: 50,
    alignSelf:'center',
    resizeMode: 'center',
  },
  codeHighlightText: {
    color: 'rgba(96,100,109, 0.8)',
  },
  codeHighlightContainer: {
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  tabBarInfoContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    ...Platform.select({
      ios: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: -3 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
      },
      android: {
        elevation: 20,
      },
    }),
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    paddingVertical: 20,
  },
  tabBarInfoText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    textAlign: 'center',
  },
  tableHeader : {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent:'space-around',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#dbdbdb',
    backgroundColor: 'white',
    padding: 10,
    alignSelf: 'center',
    width: '90%',
  },
  tableRow : {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent:'space-around',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#dbdbdb',
    backgroundColor: '#ededed',
    padding: 10,
    alignSelf: 'center',
    width: '90%',
  },
  tableHeaderText : {
    color: Colors.vitlsGray,
    fontSize: RFPercentage(2),
  },
  tableHeaderTextSmall : {
    color: Colors.vitlsGray,
    fontSize: RFPercentage(1.25),
  },
  tableTextContainer : {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent:'center',
    alignSelf: 'center',
    width: '15%',
    height: 40,
    backgroundColor: 'transparent',
  },
  tableText : {
    alignSelf: 'center',
    color: Colors.vitlsGray,
    fontSize: RFPercentage(1.5),
  },
  form: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:'white',
    marginBottom: 50,
  },
  formContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent:'space-around',
    width: screenWidth > 700 ? '40%' : '80%',
  },
  formContainerFull: {
    flex: 1,
    flexDirection: 'column',
    justifyContent:'space-around',
    width: '90%',
  },
  formLabel:{
    color:'gray',
    fontSize: RFPercentage(2),
  },
  formInput: {
    height: 60,
    fontSize: RFPercentage(2),
    borderColor: 'gray',
    borderBottomWidth: 1,
    marginBottom: 20,
    padding: 10,
  },
  formBtn: {
    borderColor: 'gray',
    height: 60,
    width: 280,
    borderWidth: 1,
    borderRadius: 30,
    padding: 10,
    margin: 20,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  formBtnText: {
    color:'gray',
    fontSize: RFPercentage(2), //24
    alignSelf: 'center',
    textDecorationLine: 'none'
  },
  formBtnSmall: {
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    padding: 5,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  formBtnSmallText: {
    color:'gray',
    fontSize: RFPercentage(1.5), //24
    alignSelf: 'center',
    textDecorationLine: 'none'
  },
  picker : {
    height: 60,
    width: '100%',
    fontSize: RFPercentage(2),
    borderColor: 'gray',
    borderWidth:0,
    borderBottomWidth: 1,
    marginBottom: 20,
    padding: 10,
  },
  categoryButton: {
    borderColor: 'gray',
    height: screenWidth > 700 ? 60 : 30,
    width: screenWidth > 700 ? '20%' : '40%',
    //maxWidth: 700/4,
    borderWidth: 1,
    borderRadius: 30,
    padding: screenWidth > 700 ? 10 : 2,
    margin: screenWidth > 700 ? 10 : 2,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  categoryHeader: {
    backgroundColor: '#ededed',
    padding:10,
    margin:10,
  },
  categoryHeaderIcon: {
    height:20,
    width:20,
    borderRadius:10,
  },
  categoryHeaderText: {
    color:'gray',
    fontSize: RFPercentage(2), //24
    paddingLeft: 20,
  },
  cardsContainer : {
    width: '100%',
    marginTop:20,
    marginBottom:20,
    justifyContent: 'center'
  },
  card : {
    margin:10,
    width: screenWidth > 700 ? '30%' : '80%',
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#dbdbdb',
    backgroundColor: '#ededed',
    padding: 15,
    paddingBottom:0,
    shadowColor: 'white',
    shadowOffset: {
    	width: 10,
    	height: 10},
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
  },
  cardLatest : {
    margin:10,
    width: screenWidth > 700 ? '40%' : '80%',
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#dbdbdb',
    backgroundColor: '#ededed',
    padding: 15,
    shadowColor: 'white',
    shadowOffset: {
      width: 10,
      height: 10},
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
  },
  notesCard : {
    margin:10,
    width: screenWidth > 700 ? '60%' : '80%',
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#dbdbdb',
    backgroundColor: '#ededed',
    padding: 15,
    shadowColor: 'white',
    shadowOffset: {
      width: 10,
      height: 10},
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
  },
  cardHeaderContainer : {
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: '#dbdbdb',
  },
  cardHeader : {
    paddingBottom: 10,
    width: '50%',
    alignItems: 'center',
  },
  cardHeaderValue : {
    fontSize: RFPercentage(1.75),
    minHeight: 30
  },
  cardHeaderLabel : {
    fontSize: RFPercentage(1),
    color: 'gray'
  },
  cardHeaderLabelSub: {
    fontSize:RFPercentage(1) * .5,
    textAlignVertical: 'bottom'
  },
  cardVital : {
    minWidth: '20%',
    alignItems: 'center',
    paddingTop: 10,
  },
  cardVitalLatest : {
    minWidth: '20%',
    alignItems: 'center',
    paddingTop: 10,
  },
  cardLabel : {
    fontSize: RFPercentage(1),
    color: 'gray'
  },
  cardValue : {
    fontSize: RFPercentage(1.5),
  },
  cardUnit: {
    fontSize: RFPercentage(1),
  },
  cardUnitSub: {
    fontSize:RFPercentage(1) * .5,
    textAlignVertical: 'bottom'
  },
  errorMessage : {
    alignSelf: 'center',
    color: Colors.vitlsOrange,
    fontSize: RFPercentage(2)
  },
  message : {
    alignSelf: 'center',
    color: Colors.vitlsGray,
    fontSize: RFPercentage(2),
  },
  plot: {
    width: '80%',
    height: screenHeight/4,
    alignSelf:'center',
  },
  grayFill: {
    backgroundColor: Colors.vitlsGray,
  },
  greenFill: {
    backgroundColor: Colors.vitlsGreen,
  },
  blueFill: {
    backgroundColor: Colors.vitlsBlue,
  },
  redFill: {
    backgroundColor: Colors.vitlsRed,
  },
  orangeFill: {
    backgroundColor: Colors.vitlsOrange,
  },
  gray: {
    color: Colors.vitlsGray,
  },
  green: {
    color: Colors.vitlsGreen,
  },
  blue: {
    color: Colors.vitlsBlue,
  },
  red: {
    color: Colors.vitlsRed,
  },
  orange: {
    color: Colors.vitlsOrange,
  },
});
