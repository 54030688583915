import * as WebBrowser from 'expo-web-browser';
import React, { useState } from 'react';
import { Image, Text, TouchableOpacity, View, TextInput} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {Picker} from '@react-native-community/picker';
import { MonoText } from '../components/StyledText';
import NumberFormat from "react-number-format";

import { styles } from '../constants/styles';

import AzureFunc from '../hooks/azureFunc';
const azf = new AzureFunc();

export default function Signup(props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDOB] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [admissionCenter, setAdmissionCenter] = useState('');
  const [ward, setWard] = useState('');
  const [role, setRole] = useState('');
  const [message, setMessage] = useState(null);
  const [showDOBCalendar, setShowDOBCalendar] = useState(false);

  async function signup(){
    if (!name || !name.split(' ').length){
      setMessage("Please include full name sperated by spaces.")
      return
    }
    if (!email || !email.includes('@')){
      setMessage("Please include valid email.")
      return
    }
    if (!gender || gender == 'gender') {
      setMessage("Please choose a gender.")
      return
    }
    if (!dob){
      setMessage("Please select a date of birth.")
      return
    }
    if (!mobile){
      setMessage("Please include a valid mobile number.")
      return
    }
    if (!admissionCenter){
      setMessage("Please include the name of your admission center.")
      return
    }
    if (!ward){
      setMessage("Please include the name of your ward.")
      return
    }
    if (!role || role == 'role'){
      setMessage("Please select your role.")
      return
    }
    const profile = {
      "name": {
        "first": name.split(' ')[0],
        "last": name.split(' ').slice(1,name.split(' ').length).join().replace(',',' ')
      },
      email, password, gender, dob,
      mobile: mobile.slice(1,mobile.length-1)}
    //console.log(profile)
    const response = await azf.signup(profile)
    //console.log(response)
    if (response.created){
      setName('')
      setEmail('')
      setPassword('')
      setGender('')
      setDOB('')
      setMobile('')
      setAdmissionCenter('')
      setWard('')
      setRole('')
      setMessage("Thanks for signing up! Please respond to the email verification before attempting login.");
    }
    else if (response.faults[0].code == "kInvalidArgument" && response.faults[0].path == "account.password" ){
      // Password not strong enough
      setMessage(response.faults[0].message)
    }else if (response.faults[0].code == "kExists" ){
      // User email already exists
      setMessage(response.faults[0].message)
    }
   }

   function selectDOB(date){
     setDOB(date.toDateString())
     setShowDOBCalendar(false)
   }

  return (
    <View style={styles.container}>
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <View style={styles.logoContainer}>
          <Image source={require('../assets/images/vitlsLogo.png')}
            style={styles.logo} />
        </View>
        <form>
        <View style={styles.form}>
          <View style={styles.formContainer}>
            <TextInput style={styles.formInput} placeholder='full name'
              placeholderTextColor="gray"
              id="name" onChangeText={text => setName(text)}
              value={name} autoCapitalize='none'/>
          </View>
          <View style={styles.formContainer}>
            <TextInput style={styles.formInput} placeholder='email'
              placeholderTextColor="gray"
              id="email" onChangeText={text => setEmail(text)}
              value={email} autoCapitalize='none'/>
          </View>
          <View style={styles.formContainer}>
            <TextInput style={styles.formInput} placeholder='password'
              placeholderTextColor="gray" secureTextEntry={true}
              id="password" onChangeText={text => setPassword(text)}
              value={password} autoCapitalize='none'/>
          </View>
          <View style={styles.formContainer}>
              <NumberFormat customInput={TextInput} format="+1 (###) ### - ####"
                allowEmptyFormatting={false} mask="_" label="Phone" name="contactPhone"
                type="text" value={mobile} onChangeText={text => setMobile(text)}
                style={styles.formInput}   placeholder='mobile' placeholderTextColor="gray" />
          </View>
          <View style={styles.formContainer}>
            <Picker selectedValue={gender}  style={styles.picker}
              onValueChange={(itemValue, itemIndex) => setGender(itemValue)} >
               <Picker.Item key={0} label={'gender'} value={'gender'} />
               <Picker.Item key={1} label={'Female'} value={'f'} />
               <Picker.Item key={2} label={'Male'} value={'m'} />
               <Picker.Item key={3} label={'Undisclosed'} value={'u'} />
            </Picker>
          </View>
          <View style={styles.formContainer}>
            <NumberFormat customInput={TextInput} format="####/##/##"
              allowEmptyFormatting={false} mask="_" label="dob" name="dob"
              type="text" value={dob} onChangeText={text => setDOB(text)}
              style={styles.formInput}   placeholder='dob YYYY/MM/DD' placeholderTextColor="gray" />
          </View>
          <View style={styles.formContainer}>
            <TextInput style={styles.formInput} placeholder='admission center'
              placeholderTextColor="gray"
              id="admissionCenter" onChangeText={text => setAdmissionCenter(text)}
              value={admissionCenter} autoCapitalize='none'/>
          </View>
          <View style={styles.formContainer}>
            <TextInput style={styles.formInput} placeholder='ward'
              placeholderTextColor="gray"
              id="ward" onChangeText={text => setWard(text)}
              value={ward} autoCapitalize='none'/>
          </View>
          <View style={styles.formContainer}>
            <Picker selectedValue={role}  style={styles.picker}
              onValueChange={(itemValue, itemIndex) => setRole(itemValue)} >
               <Picker.Item key={0} label={'role'} value={'role'} />
               <Picker.Item key={1} label={'Nurse'} value={'Nurse'} />
               <Picker.Item key={2} label={'Doctor'} value={'Doctor'} />
               <Picker.Item key={3} label={'Care Giver'} value={'CareGiver'} />
               <Picker.Item key={4} label={'Patient'} value={'Patient'} />
               <Picker.Item key={5} label={'Administrator'} value={'Administrator'} />
            </Picker>
          </View>
          {message &&
            <View style={styles.formContainer}>
              <Text style={styles.errorMessage}>{message}</Text>
            </View>
          }

          <TouchableOpacity style={styles.formBtn} title="Signup" onPress={() => signup()}>
              <Text style={styles.formBtnText}>Signup</Text>
          </TouchableOpacity>
        </View>
        </form>
      </ScrollView>
      <View style={styles.tabBarInfoContainer}>
        <Text style={styles.tabBarInfoText}>Back to
          <MonoText onPress={() => props.navigation.navigate('login')} style={styles.codeHighlightText}> Login</MonoText>
          .
        </Text>
      </View>
    </View>
  );
}
