import { styles } from '../constants/styles';
import * as React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import AzureFunc from '../hooks/azureFunc';

const azf = new AzureFunc();

export default function PatientInfoEditable(props) {
 
    const admittedDate = props.data.admitted ? new Date(props.data.admitted) : new Date();
    const weightUnit = !props.user.units ? 'Kg' : 'lbs';
    const heightUnit = !props.user.units ? 'm' : 'ft';
    const patientHeight = props.data.patient ? props.data.patient.height ? props.data.patient.height : 0 : 0;
    const patientWeight = props.data.patient ? props.data.patient.weight ? props.data.patient.weight : 0 : 0;
    const patientDOB = props.data.patient ? props.data.patient.dob ? props.data.patient.dob.replace("_","") : "" : "";
    
    return (
        <View style={[styles.col,styles.card]}>
            <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
                <Text style={styles.cardHeaderValue}>Patient Info</Text>
            </View>
            <Text style={styles.cardHeaderLabel}>Patient ID:</Text>
            <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{props.data.patient ? props.data.patient.id : ""}</Text>
            <Text style={styles.cardHeaderLabel}>Device ID:</Text>
            <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{props.data.deviceId}</Text>
            <Text style={styles.cardHeaderLabel}>Patient Name:</Text>
            <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{props.data.patient ? props.data.patient.name : ""}</Text>
            <View style={styles.row}>
            <View style={[styles.col,{width:'50%'}]}>
                <Text style={styles.cardHeaderLabel}>Room:</Text>
                <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{props.data.room}</Text>
            </View>
            <View style={[styles.col,{width:'50%'}]}>
                <Text style={styles.cardHeaderLabel}>Gender:</Text>
                <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{props.data.patient? props.data.patient.gender : "u"}</Text>
            </View>
            </View>
            <View style={styles.row}>
            <View style={[styles.col,{width:'50%'}]}>
                <Text style={styles.cardHeaderLabel}>Age (years):</Text>
                <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{props.data.patient ? props.data.patient.age : ""}</Text>
            </View>
            <View style={[styles.col,{width:'50%'}]}>
                <Text style={styles.cardHeaderLabel}>DOB:</Text>
                <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{patientDOB}</Text>
            </View>
            </View>
            <View style={styles.row}>
            <View style={[styles.col,{width:'50%'}]}>
                <Text style={styles.cardHeaderLabel}>Weight ({weightUnit}):</Text>
                <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{!props.user.units ? patientWeight.toFixed(1) :
                parseFloat(patientWeight / 0.453592).toFixed(1) }</Text>
            </View>
            <View style={[styles.col,{width:'50%'}]}>
                <Text style={styles.cardHeaderLabel}>Height ({heightUnit}):</Text>
                <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{!props.user.units ? patientHeight.toFixed(2) :
                parseFloat((patientHeight / 0.3048).toFixed(1)) }</Text>
            </View>
            </View>
            <Text style={styles.cardHeaderLabel}>Diagnosis:</Text>
            <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{props.data.diagnosis}</Text>
            <Text style={styles.cardHeaderLabel}>Admitted:</Text>
            <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{admittedDate.toLocaleString()}</Text>
            <Text style={styles.cardHeaderLabel}>Last Vitals Obtained:</Text>
            <Text style={[styles.cardHeaderValue,{alignSelf:'center'}]}>{(props.latestVitals.time < admittedDate) ? 'No data' : props.latestVitals.time.toLocaleString()}</Text>
            <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => props.setViewPatientInfoModal(true)}>
                <Text style={styles.formBtnText}>Update</Text>
            </TouchableOpacity>
        </View>
    );
}