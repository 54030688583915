import * as WebBrowser from 'expo-web-browser';
import React, { useState, useEffect } from 'react';
import { Image, Text, TouchableOpacity, View , FlatList, TextInput, Dimensions} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { styles } from '../constants/styles';
import { MonoText } from '../components/StyledText';
import CategoryHeader from '../components/CategoryHeader';
import VitalsCard from '../components/VitalsCard';
import DeviceRow from '../components/DeviceRow';
//import Sound from 'react-sound';
import useSound from 'use-sound';
import alertSound from '../assets/sounds/alert.mp3';
import AzureFunc from '../hooks/azureFunc';
import { getActiveDevices } from '../hooks/getActiveDevices';
import localforage from 'localforage';

const azf = new AzureFunc();
const screenWidth = Dimensions.get("window").width;

export default function Dashboard({user, navigation, route }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isViewing, setIsViewing] = useState(true);
  const [message, setMessage] = useState("Loading...");
  const [fetchData, setFetchData] = useState(true);
  const [numActiveDevices, setNumActiveDevices] = useState(0);
  const [category, setCategory] = useState('unstable');
  const [patientID, setPatientID] = useState('');
  const [pause, setPause] = useState(false);
  const [playSound, setPlaySound] = useState(true);
  const [ackAdm, setAckAdm] = useState({unstable:[],atRisk:[],offline:[]});
  const [flash, setFlash] = useState({unstable:false,atRisk:false,offline:false});
  const [flashTimer1, setFlashTimer1] = useState({unstable:null,atRisk:null,offline:null});
  const [flashTimer2, setFlashTimer2] = useState({unstable:null,atRisk:null,offline:null});
  const [unstableMessage, setUnstableMessage] = useState(['']);
  const [studyView, setStudyView] = useState(true);
  const [studyData, setStudyData] = useState([])

  async function getWards(){
    let wards = await azf.getWard(user.selectedRegistration.admissionCenterId)
    if (!wards.data.length){
      return
    }
    let ward = wards.data.filter(w => w.id == user.selectedRegistration.wardId)[0]
    if (!ward.study) {
      setStudyView(false);
    }

    // Check local for old data to fast load dashboard
    localforage.getItem("vitls-dashboard-admissions")
    .then((item) => {
      setData(item)
      processData(item)
    })
    .catch((e) => console.log(e))
  }

  function processData(data){
    let categories = ['unstable', 'atRisk', 'offline']
      if(studyView){
        let sData = []
        for(let c in categories){
          sData = [...sData, ...data[categories[c]]]
        }
        setStudyData(sData)
      } else {
        categories = ['unstable', 'atRisk', 'offline']
        categories.map(cat => {
          if(data[cat].length){
            let newCat = data[cat]
            newCat = data[cat].filter(adm => !ackAdm[cat].includes(adm.admissionID))
            //console.log(newCat)
            if(cat == 'unstable' && newCat.length){
              play()
              setPlaySound(true)
              clearInterval(flashTimer1[cat])
              clearInterval(flashTimer2[cat])
              setFlashTimer1({...flashTimer1,
                [cat]:setInterval(() => setFlash({...flash, [cat]:true}), 500)})
              setFlashTimer2({...flashTimer2,
                  [cat]:setInterval(() => setFlash({...flash, [cat]:false}), 1000)})
            }else if (newCat.length){
            setFlash({...flash, [cat]:true})
            }
          }
          if(data[cat].length < ackAdm[cat].length){
            const updatedCat = data[cat].map((adm) => {return(adm.admissionID)})
            //console.log(updatedCat)
            setAckAdm({...ackAdm, [cat]:updatedCat})
          }
        })
      }
      setIsLoading(false);
  }

  useEffect(()=>{    
    // Check if ward is study or full data view for dashboard
    getWards()
  },[]);

  useEffect(() => {
    // Get new data update on interval
    getActiveDevices(user, setData, setIsLoading, setNumActiveDevices, setMessage, pause, setPause, isViewing);
    if(data){
      processData(data)
      localforage.setItem("vitls-dashboard-admissions", data)
    }
  },[fetchData]);

  var fetchDataTimer = setTimeout(() => {
    if(isViewing){
      setFetchData(!fetchData);
    }
 },10000); //Update data every 10 seconds

 if(!isViewing){
  clearTimeout(fetchDataTimer);
 }

  function pauseUpdate(){
    setPause(!pause)
    //console.log(pause)
  }

  function handleCategory(cat) {
    setCategory(cat)
    clearInterval(flashTimer1[cat])
    clearInterval(flashTimer2[cat])
    setFlash({...flash, [cat]:false})
    const currentCat = data[cat].map((adm) => {return(adm.admissionID)})
    //console.log(currentCat)
    setAckAdm({...ackAdm, [cat]:currentCat})
  }

  //console.log(flash)
  function ackAlarm(){
    setPlaySound(false)
    stop()
    if(!data.unstable.length){
      return
    }
    const currentUnstable = data.unstable.map((adm) => {return(adm.admissionID)})
    //console.log(currentUnstable)
    setAckAdm({...ackAdm, unstable:currentUnstable})
  }

  const [play, { stop, isPlaying }] = useSound(
    alertSound,
    { volume: 0.5 }
  );

 const renderItem = ({ item }) => {
   return (
     <VitalsCard setIsViewing={setIsViewing} navigation={navigation} data={item} options={user}/>
   );
 };

 const cardCategory = (data) => {
   let catIcon = null;
   switch(category){
     case 'Stable':
      catIcon = styles.greenFill
      break;
    case 'At Risk':
      catIcon = styles.orangeFill
      break;
    case 'Unstable':
      catIcon = styles.redFill
      break;
    case 'Offline':
      catIcon = styles.grayFill
      break;
   }
    if (screenWidth > 700){
      return (
        <View style={styles.cardsContainer}>
            <CategoryHeader category={category} categoryIcon={catIcon}
              value={data.length} total={numActiveDevices}/>
              <FlatList data={data.filter(admission => admission.patient.id.includes(patientID))} renderItem={renderItem}
                keyExtractor={(item) => item.id} numColumns={3} columnWrapperStyle={{justifyContent:'center'}}>
              </FlatList>
          </View>
      );
    }else {
      return (
        <View style={styles.cardsContainer}>
            <CategoryHeader category={category} categoryIcon={catIcon}
              value={data.length} total={numActiveDevices}/>
              <FlatList data={data.filter(admission => admission.patient.id.includes(patientID))} renderItem={renderItem}
                keyExtractor={(item) => item.id} >
              </FlatList>
          </View>
      );
    }
  };

  return (
    <View style={styles.container}>
    {isLoading ?
      <View style={styles.col}>
        <Text style={styles.message}>{message}</Text>
      </View>
      :
      <>
      {studyView ?
        <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <View style={styles.tableHeader}>
          <View style={styles.tableTextContainer}>
            <Text  style={styles.tableHeaderText}>Patient ID</Text>
          </View>
          <View style={styles.tableTextContainer}>
            <Text  style={styles.tableHeaderText}>Device ID</Text>
          </View>
          <View style={[styles.tableTextContainer,{width:'6%'}]}>
            <Text  style={styles.tableHeaderText}>Bed</Text>
          </View>
          <View style={[styles.tableTextContainer,{width:'6%'}]}>
            <Text  style={styles.tableHeaderText}>Room</Text>
          </View>
          <View style={[styles.tableTextContainer,{width:'20%'}]}>
            <Text  style={styles.tableHeaderText}>Updated</Text>
          </View>
          <View style={styles.tableTextContainer}>
            <Text  style={styles.tableHeaderText}>State</Text>
          </View>
        </View>
        {studyData.map((device) =>
          <DeviceRow key={device.id} device={device} navigation={navigation}/>
        )}
        </ScrollView>
        :
        <>
      <View style={styles.row}>
        <TouchableOpacity style={[styles.categoryButton, {
          backgroundColor: category == "unstable" ? '#ededed' : 'white',
          borderColor: flash.unstable ? '#c90000' : 'gray',
          borderWidth: flash.unstable ? 3 : 1}]}
          onPress={() => handleCategory('unstable')}>
            <Text style={styles.formBtnText}>{data.unstable.length.toString() + ' Unstable'}</Text>
        </TouchableOpacity>

        <TouchableOpacity style={[styles.categoryButton, {
          backgroundColor: category == "atRisk" ? '#ededed' : 'white',
          borderColor: flash.atRisk ? '#faae81' : 'gray',
          borderWidth: flash.atRisk ? 3 : 1}]}
          onPress={() => handleCategory('atRisk')}>
            <Text style={styles.formBtnText}>{data.atRisk.length.toString() + ' At Risk'}</Text>
        </TouchableOpacity>

        <TouchableOpacity style={[styles.categoryButton, {
          backgroundColor: category == "stable" ? '#ededed' : 'white'}]}
          onPress={() => handleCategory('stable')}>
            <Text style={styles.formBtnText}>{data.stable.length.toString() + ' Stable'}</Text>
        </TouchableOpacity>

        <TouchableOpacity style={[styles.categoryButton, {
          backgroundColor: category == "offline" ? '#ededed' : 'white',
          borderColor: flash.offline ? '#faae81' : 'gray',
          borderWidth: flash.offline ? 3 : 1}]}
          onPress={() => handleCategory('offline')}>
            <Text style={styles.formBtnText}>{data.offline.length.toString() + ' Offline'}</Text>
        </TouchableOpacity>
      </View>

      <View style={[styles.row, {alignSelf: 'center', width:'80%', maxWidth:720}]}>
        <View style={styles.formContainer}>
          <TextInput style={styles.formInput} placeholder={'patient ID'}
            placeholderTextColor = "gray"
            id="patientID" onChangeText={text => setPatientID(text)}
            value={patientID} autoCapitalize='none'/>
        </View>
        <TouchableOpacity style={[styles.categoryButton, {
          backgroundColor: pause ? '#ededed' : 'white'}]}
          onPress={() => play()}>
            <Text style={styles.formBtnText}>{pause ? 'Update' : 'Pause'}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[styles.categoryButton, {
          backgroundColor: 'white'}]}
          onPress={() => ackAlarm()}>
          {playSound ?
            <Image source={require('../assets/images/alarmMute.png')}
              style={styles.alarmSymbol} />
              :
            <Image source={require('../assets/images/alarmUnMute.png')}
              style={styles.alarmSymbol} />
          }
        </TouchableOpacity>
      </View>

      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>

      {(data.unstable.length && category == 'unstable')?
        cardCategory(data.unstable)
        :
        null
      }

      {(data.atRisk.length && category == 'atRisk')?
        cardCategory(data.atRisk)
        :
        null
      }

      {(data.stable.length && category == 'stable') ?
        cardCategory(data.stable)
        :
        null
      }

      {(data.offline.length && category == 'offline')?
        cardCategory(data.offline)
        :
        null
      }
      </ScrollView>
    </>

      }
      </>
    }
  </View>
  );
}

Dashboard.navigationOptions = {
  header: null,
};