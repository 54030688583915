import { Ionicons } from '@expo/vector-icons';
import * as WebBrowser from 'expo-web-browser';
import React, {useState, useEffect, useReducer} from 'react';
import { Text, View, TouchableOpacity, TextInput } from 'react-native';
import {Picker} from '@react-native-community/picker';
import { RectButton, ScrollView } from 'react-native-gesture-handler';
import { styles } from '../constants/styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import AzureFunc from '../hooks/azureFunc';
const azf = new AzureFunc();

export default function Admin(props, { navigation, route }) {

 const [admissionCenterID, setAdmissionCenterID] = useState(props.user.selectedRegistration.admissionCenterId);
 const [admissionCenterName, setAdmissionCenterName] = useState(props.user.selectedRegistration.admissionCenterName);
 const [wardID, setWardID] = useState(props.user.selectedRegistration.wardId);
 const [wardName, setWardName] = useState(props.user.selectedRegistration.wardName);
 const [userEmail, setUserEmail] = useState('');
 const [userEmailUA, setUserEmailUA] = useState('');
 const [userEmailUW, setUserEmailUW] = useState('');
 const [userEmailDelete, setUserEmailDelete] = useState('');
 const [messageAdd, setMessageAdd] = useState("");
 const [messageEnroll, setMessageEnroll] = useState("");
 const [messageUA, setMessageUA] = useState("");
 const [messageUW, setMessageUW] = useState("");
 const [messageDelete, setMessageDelete] = useState("");
 const [newUser, setNewUser] = useState({firstName:'',lastName:'', middleName:'',email:''});
 const { instance, inProgress, accounts } = useMsal();

 const [deviceIds, setDeviceIds] = useState("");
 const [messageAddDevices, setMessageAddDevices] = useState("");

 //console.log(accounts)
 let adminUser = false;
 if (props.user.role == 0){
  adminUser = true;
 }
let accessToken = "";
 async function sendInvite(){
  // Send email invite using graph api
  let userInvite = {
    invitedUserDisplayName: `${newUser.firstName} ${newUser.lastName}`,
    invitedUserEmailAddress: newUser.email.toLowerCase().replaceAll(" ",""),
    inviteRedirectUrl: "https://data.vitlsinc.com",
    sendInvitationMessage: true,
    invitedUserMessageInfo: {
      customizedMessageBody: `Hello,

      This is an invite to create a new account for Vitls. After accepting the invite and logging in with your Microsoft account, go to 
      https://data.vitlsinc.com to login, access patient enrollment and view data.

      Please contact me at mohamed@vitlsinc.com when you first login successfully to data.vitlsinc.com or if you have any issues. 

      Peace,
      Mohamed Elmahdy
      EVP of Product
      Vitls Inc.`
      }
    }
    //console.log(userInvite)
    //console.log( `Bearer ${accessToken}`)
    let inviteResp = ""
    await fetch("https://graph.microsoft.com/v1.0/invitations", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type":"application/json"
      },
      body: JSON.stringify(userInvite)
    })
    .then((resp) => {
      inviteResp = resp.json()
      //console.log(inviteResp)
    })
    .catch((error) => {
      console.log(error)
    })
 }

let tenantID = "cdcab5e8-5dfd-4e99-bc49-053df6ab0466"
let clientID = "d89dc686-ee9a-4942-b76a-4a74dac35dc3"
let clientSecret = "1Uw8Q~v5Hht-3CMjk8S_AoZ1uY4w.TY0h9Qm5cM5"
let scope = "User.Read api://0848ecb5-3525-45d2-a5c3-8185ff26a97e/Invoke"
let authorizationCode = ""
 async function addNewUser(){

  // First check if user with that email exists
  const allUsers = await azf.getAllUserInfo();
  if(!allUsers.data){
    setMessageAdd("You are logged out. Please log back in.")
    return
  }
  let users = allUsers.data.filter(user => user.userEmail == newUser.email.toLowerCase().replaceAll(" ",""))
  if(users.length > 0){
    setMessageAdd("User with that email already exists.")
    return
  }

  let name = {'first': newUser.firstName, 'middle': newUser.middleName, 'last':newUser.lastName}
  let userInfo = {
    "created": new Date().toISOString(),
    "updated":new Date().toISOString(),
    "userId": "", 
    name,
    "username": newUser.email.toLowerCase().replaceAll(" ",""),
    "userEmail": newUser.email.toLowerCase().replaceAll(" ",""),
    "tenantId": "",
    "units": 1, // 1 imperial, 0 metric
    "registrations": [], // array of {[admissionCenterId:,admissionCenterName, wards:[{id:, name:}],...}
    "selectedRegistration": {}, // {admissionCenterId, admissionCenterName, wardId, wardName}
    "role": 2, // 0 admin, 1 doctor, 2 nurse, 3 remote,
    "stats": {'lastLoginTime': new Date().toISOString(), 'lastLoginIP': '', 'lastPasswordReset': '', 'tz': ''}
  }
  const resp = await azf.insertUserInfo(userInfo)
  if(resp.id){
    setMessageAdd("New user added to Vitls Backend! Sending email invite...")
  } else {
    setMessageAdd("Could not add new user")
  }

  // Get token to call graph api
  const accessTokenRequest = {
    scopes: ["user.read"],
    account: accounts[0],
  };
  instance
  .acquireTokenSilent(accessTokenRequest)
  .then(async (accessTokenResponse) => {
    // Acquire token silent success
    accessToken = accessTokenResponse.accessToken;
    sendInvite();
 })
  .catch((error) => {
    console.log("Authorization code could not be obtained silently.")
      instance
        .acquireTokenPopup(accessTokenRequest)
        .then(function (accessTokenResponse) {
          // Acquire token interactive success
          accessToken = accessTokenResponse.accessToken;
          // Call your API with token
          sendInvite();
        })
        .catch(function (error) {
          // Acquire token interactive failure
          console.log(error);
        });
  });

  
  

  console.log("Outside of invite")
  setTimeout(function(){ setMessageAdd(""); }, 3000);
  setTimeout(function(){ setNewUser({}); }, 3000);
 }

  async function enrollUser(){
    var userEmails = userEmail.trim().split(',')

    if(userEmails[0].length && admissionCenterID){
      const allUsers = await azf.getAllUserInfo();
      if(!allUsers.data){
        setMessageEnroll("You are logged out. Please log back in.")
        return
      }
      for (var uE in userEmails) {
        if(allUsers.data.length){
          let users = allUsers.data.filter(user => user.userEmail == userEmails[uE].toLowerCase().replaceAll(" ",""))
          if(!users.length){
            setMessageEnroll(`No user found for email "${userEmails[uE]}"`)
            return
          }
          if(users.length > 1){
            setMessageEnroll(`Found multiple user profiles for "${userEmails[uE]}"`)
            return
          }
          
          const resp = await azf.enrollUser(users[0], admissionCenterID, admissionCenterName, wardID, wardName);
          if(resp.id){
            setMessageEnroll("Enrollment succesful!")
            
          } else if(resp.enrolled) {
            setMessageEnroll(`User already enrolled "${userEmails[uE]}"`)
          } else {
            setMessageEnroll(`Could not enroll user "${userEmails[uE]}"`)
          }
        } else {
          setMessageEnroll("Could not get all user info")
        }
      }
    } else {
      setMessageEnroll("Please add user email(s)")
    }
    setTimeout(function(){ setMessageEnroll(""); }, 3000);
  }

  async function unEnrollFromAdmissionCenter(){
    var userEmails = userEmailUA.trim().split(',')

    if(userEmails[0].length && admissionCenterID){
      const allUsers = await azf.getAllUserInfo();
      if(!allUsers.data){
        setMessageUA("You are logged out. Please log back in.")
        return
      }
      for (var uE in userEmails) {
        if(allUsers.data.length){
          let users = allUsers.data.filter(user => user.userEmail == userEmails[uE].toLowerCase().replaceAll(" ",""))
          if(!users.length){
            setMessageUA(`No user found for email "${userEmails[uE]}"`)
            return
          }
          if(users.length > 1){
            setMessageUA(`Found multiple user profiles for "${userEmails[uE]}"`)
            return
          }

          const result = await azf.unEnrollFromAdmissionCenter(users[0], admissionCenterID);
          if(result.id){
            setMessageUA("Unerollment successful!")
          } else {
            setMessageUA(`Could not unenroll user "${userEmails[uE]}"`)
          }
        }
      }
    }else {
      setMessageUA("Please add user email(s)")
    }
    setTimeout(function(){ setMessageUA(""); }, 3000);
  }

  async function unEnrollFromWard(){
    var userEmails = userEmailUW.trim().split(',')

    if(userEmails[0].length && admissionCenterID){
      const allUsers = await azf.getAllUserInfo();
      if(!allUsers.data){
        setMessageUW("You are logged out. Please log back in.")
        return
      }
      for (var uE in userEmails) {
        if(allUsers.data.length){
          let users = allUsers.data.filter(user => user.userEmail == userEmails[uE].toLowerCase().replaceAll(" ",""))
          if(!users.length){
            setMessageUW(`No user found for email "${userEmails[uE]}"`)
            setTimeout(function(){ setMessageUW(""); }, 3000);
            return
          }
          if(users.length > 1){
            setMessageUW(`Found multiple user profiles for "${userEmails[uE]}"`)
            return
          }

          const result = await azf.unEnrollFromWard(users[0], admissionCenterID, wardID);
          if(result.id){
            setMessageUW("Unerollment successful!")
          } else {
            setMessageUW(`Could not unenroll user "${userEmails[uE]}"`)
          }
        }
      }
    }else {
      setMessageUW("Please add user email(s)")
    }
    setTimeout(function(){ setMessageUW(""); }, 3000);
  }

  async function deleteUser(){
    var userEmails = userEmailDelete.trim().split(',')

    if(userEmails[0].length){
      const allUsers = await azf.getAllUserInfo();
      if(!allUsers.data){
        setMessageDelete("You are logged out. Please log back in.")
        return
      }
      for (var uE in userEmails) {
        if(allUsers.data.length){
          let users = allUsers.data.filter(user => user.userEmail == userEmails[uE].toLowerCase())
          if(!users.length){
            setMessageDelete(`No user found for email "${userEmails[uE]}"`)
            setTimeout(function(){ setMessageDelete(""); }, 3000);
            return
          }
          const result = await azf.deleteUser(users[0]);

          if(result.id){
            setMessageDelete("User(s) Deleted!")
          } else {
            setMessageDelete(`Could not delete user "${userEmails[uE]}"`)
          }
        }
      }
    }else {
      setMessageDelete("Please add user email(s)")
    }
    setTimeout(function(){ setMessageDelete(""); }, 3000);
  }

  async function addDevices(){
    console.log(deviceIds)
    var deviceIdsArr = deviceIds.trim().split(',')
    deviceIdsArr.map(async (d) =>  {
      if(d.length == 12){ // is each deviceId 12 characters long?
        const deviceInfoToAdd = {"mfgTime": "", "updated": new Date().toISOString(), "batchId": "",
          "deviceId": d, "admissionCenterId": admissionCenterID,
          "fwVersion": "", "hwVersion": ""}
        const result = await azf.addDevice(deviceInfoToAdd);
      }
    })
  }

  return (
    <View style={styles.container}>
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
      <View style={[styles.row, {justifyContent:'center'}]}>
      {adminUser &&
          <View style={[styles.col, styles.card, {width:'70%'}]}>
            <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
              <Text style={styles.cardHeaderValue}>Add New User and Send Invite Email</Text>
            </View>
            <View style={styles.formContainerFull}>
              <TextInput style={styles.formInput} placeholder='First Name'
                placeholderTextColor = "gray" id="newUserFirstName" onChangeText={firstName => setNewUser((prev) => {return {...prev, firstName}})}
                value={newUser.firstName} autoCapitalize='none'/>
            </View>
            <View style={styles.formContainerFull}>
              <TextInput style={styles.formInput} placeholder='Middle Name'
                placeholderTextColor = "gray" id="newUserMiddleName" onChangeText={middleName => setNewUser((prev) => {return {...prev, middleName}})}
                value={newUser.middleName} autoCapitalize='none'/>
            </View>
            <View style={styles.formContainerFull}>
              <TextInput style={styles.formInput} placeholder='Last Name'
                placeholderTextColor = "gray" id="newUserLastName" onChangeText={lastName => setNewUser((prev) => {return {...prev, lastName}})}
                value={newUser.lastName} autoCapitalize='none'/>
            </View>
            <View style={styles.formContainerFull}>
              <TextInput style={styles.formInput} placeholder='Email'
                placeholderTextColor = "gray" id="newUserEmail" onChangeText={email => setNewUser((prev) => {return {...prev, email}})}
                value={newUser.email} autoCapitalize='none'/>
            </View>
            <View style={[styles.col]}>
              <Text style={styles.errorMessage}>{messageAdd}</Text>
            </View>
            <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => addNewUser()}>
                <Text style={styles.formBtnText}>Add</Text>
            </TouchableOpacity>
          </View>
        }

        {adminUser &&
          <View style={[styles.col, styles.card, {width:'70%'}]}>
            <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
              <Text style={styles.cardHeaderValue}>Enroll Users</Text>
            </View>
            <View style={styles.formContainerFull}>
              <TextInput style={[styles.formInput, {height:120}]} placeholder='user email(s) (seperated by commas)'
                placeholderTextColor = "gray" multiline={true}
                id="userEmail" onChangeText={text => setUserEmail(text)}
                value={userEmail} autoCapitalize='none'/>
            </View>
            <View style={[styles.col]}>
              <Text style={styles.errorMessage}>{messageEnroll}</Text>
            </View>
            <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => enrollUser()}>
                <Text style={styles.formBtnText}>Enroll</Text>
            </TouchableOpacity>
          </View>
        }

        {adminUser &&
          <View style={[styles.col, styles.card, {width:'70%'}]}>
            <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
              <Text style={styles.cardHeaderValue}>Unenroll Users from Admission Center</Text>
            </View>
            <View style={styles.formContainerFull}>
              <TextInput style={[styles.formInput, {height:120}]} placeholder='user email(s) (seperated by commas)'
                placeholderTextColor = "gray" multiline={true}
                id="userEmailUA" onChangeText={text => setUserEmailUA(text)}
                value={userEmailUA} autoCapitalize='none'/>
            </View>
            <View style={[styles.col]}>
              <Text style={styles.errorMessage}>{messageUA}</Text>
            </View>
            <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => unEnrollFromAdmissionCenter()}>
                <Text style={styles.formBtnText}>Unenroll</Text>
            </TouchableOpacity>
          </View>
        }

        {adminUser &&
          <View style={[styles.col, styles.card, {width:'70%'}]}>
            <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
              <Text style={styles.cardHeaderValue}>Unenroll Users from Ward</Text>
            </View>
            <View style={styles.formContainerFull}>
              <TextInput style={[styles.formInput, {height:120}]} placeholder='user email(s) (seperated by commas)'
                placeholderTextColor = "gray" multiline={true}
                id="userEmailUW" onChangeText={text => setUserEmailUW(text)}
                value={userEmailUW} autoCapitalize='none'/>
            </View>
            <View style={[styles.col]}>
              <Text style={styles.errorMessage}>{messageUW}</Text>
            </View>
            <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => unEnrollFromWard()}>
                <Text style={styles.formBtnText}>Unenroll</Text>
            </TouchableOpacity>
          </View>
        }

        {adminUser &&
          <View style={[styles.col, styles.card, {width:'70%'}]}>
            <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
              <Text style={styles.cardHeaderValue}>Delete Users</Text>
            </View>
            <View style={styles.formContainerFull}>
              <TextInput style={[styles.formInput, {height:120}]} placeholder='user email(s) (seperated by commas)'
                placeholderTextColor = "gray" multiline={true}
                id="userEmail" onChangeText={text => setUserEmailDelete(text)}
                value={userEmailDelete} autoCapitalize='none'/>
            </View>
            <View style={[styles.col]}>
              <Text style={styles.errorMessage}>{messageDelete}</Text>
            </View>
            <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => deleteUser()}>
                <Text style={styles.formBtnText}>Delete</Text>
            </TouchableOpacity>
          </View>
        }

        {adminUser &&
          <View style={[styles.col, styles.card, {width:'70%'}]}>
            <View style={[styles.cardHeaderContainer,{marginBottom:20}]}>
              <Text style={styles.cardHeaderValue}>Add Device(s) to Backend</Text>
            </View>
            <View style={styles.formContainerFull}>
              <TextInput style={[styles.formInput, {height:120}]} placeholder='device IDs (seperated by commas)'
                placeholderTextColor = "gray" multiline={true}
                id="deviceIds" onChangeText={text => setDeviceIds(text)}
                value={deviceIds} autoCapitalize='none'/>
            </View>
            <View style={[styles.col]}>
              <Text style={styles.errorMessage}>{messageAddDevices}</Text>
            </View>
            <TouchableOpacity style={[styles.formBtn,{backgroundColor:'white'}]} onPress={() => addDevices()}>
                <Text style={styles.formBtnText}>Add</Text>
            </TouchableOpacity>
          </View>
        }
        </View>
    </ScrollView>
    </View>
  );
}