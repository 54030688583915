import { classifyAdmission } from './classifyAdmission';

import AzureFunc from '../hooks/azureFunc';
const azf = new AzureFunc();

export async function getActiveDevices(user, setData, setIsLoading, setNumActiveDevices,
  setMessage, pause, setPause, isViewing){


  let data = {stable:[],atRisk:[],unstable:[],offline:[]};

  if(!user.selectedRegistration.admissionCenterId){
    setData(data);
    setMessage("No admission center set. Please select one in User tab.")
    return
  }

  if(pause){
    setTimeout(() => setPause(!pause),30000);
    return
  }

  if(!isViewing){
    return
  }

  let limit = 100; // Limit number of active devices to obtain

  let activeAdmissionsResults = await azf.getActiveAdmissions(
    user.selectedRegistration.admissionCenterId, user.selectedRegistration.wardId, limit);

  let activeAdmissions = [];
  if(activeAdmissionsResults) {
      activeAdmissions = activeAdmissionsResults.data
  }

  if(!isViewing){
    return
  }

  //console.log(activeAdmissions)

  if(activeAdmissions.length){
    // Get Latest vitals for each active admission
    let allDeviceIds = []
    activeAdmissions.map(admission => {
      allDeviceIds.push(`"${admission.deviceId}"`)
    })
    
    let latestVitals = await azf.getLatestVitals(allDeviceIds)
    //console.log(latestVitals)
    //console.log(allDeviceIds)
    if(!isViewing){
      return
    }

    if(latestVitals){
      latestVitals = latestVitals.data
      if(latestVitals.length){
        activeAdmissions.map(admission => {
          let index = latestVitals.findIndex(latestVital =>
            admission.deviceId == latestVital.deviceId);
          //console.log(new Date(latestVitals[index].time), new Date())
          if(index != -1){
            if (new Date(latestVitals[index].time) > new Date()){
              index = -1
            }

            if (new Date(latestVitals[index].time) < new Date(admission.admitted)){
              index = -1
            }
          }
          
          let addDevice = {};
          if(index != -1){
            if((latestVitals[index].spo2 > 100) || (latestVitals[index].hr > 240)){
              latestVitals[index].spo2 = latestVitals[index].spo2/10
              latestVitals[index].hr = latestVitals[index].hr/10
              latestVitals[index].temperature = latestVitals[index].temperature/100
              latestVitals[index].battery = latestVitals[index].battery/100
            }
            addDevice = {...admission, ...latestVitals[index], ...{admissionID:admission.id}};
            //console.log(addDevice)
            addDevice = classifyAdmission(addDevice)
          }else{
            const noDataAdmission = {
              temperature: 0,
              hr: 0,
              spo2: 0,
              rr: 0,
              battery: 0,
              category: 'offline'
            }
            addDevice = {...admission, ...noDataAdmission};
          }
          data[addDevice.category].push(addDevice)
        });

          if(!isViewing){
            return
          }
          setData(data);
          setNumActiveDevices(activeAdmissions.length);
          return;
        }else {
          setMessage("No vitals data for current active devices.")
          return;
        }
      }
    }else {
      setMessage("No active devices found. Please add a patient.")
      return;
    }
  setMessage("Could not load latest admissions data.")
  return;
}
