import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';
import { View, Text, Image, Dimensions } from 'react-native';

import TabBarIcon from '../components/TabBarIcon';
import Dashboard from '../screens/Dashboard';
import AddPatient from '../screens/AddPatient';
import PatientHistory from '../screens/PatientHistory';
import User from '../screens/User';
import PatientData from '../screens/PatientData';
import Admin from '../screens/Admin';
import { styles } from '../constants/styles';

const BottomTab = createBottomTabNavigator();
const INITIAL_ROUTE_NAME = 'Dashboard';

const screenWidth = Dimensions.get("window").width;

export default function BottomTabNavigator({ auth, user, navigation, route }) {
  // Set the header title on the parent stack navigator depending on the
  // currently active tab. Learn more in the documentation:
  // https://reactnavigation.org/docs/en/screen-options-resolution.html
  //navigation.setOptions({ headerTitle: getHeaderTitle(route) });
  navigation.setOptions({ headerTitle: <LogoTitle user={user}/> });
  
  return (
    <BottomTab.Navigator initialRouteName={INITIAL_ROUTE_NAME}>
      <BottomTab.Screen
        name="Dashboard"
        options={{
          title: 'Dashboard',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-browsers" />,
          unmountOnBlur: true,
        }}
      >
        {props => <Dashboard {...props} user={user} auth={auth} />}
      </BottomTab.Screen>

      <BottomTab.Screen
        name="PatientData"
        options={{
          title: 'Plots',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-analytics" />,
          unmountOnBlur: true,
        }}
      >
        {props => <PatientData {...props} user={user} auth={auth} />}
      </BottomTab.Screen>

      <BottomTab.Screen
        name="AddPatient"
        options={{
          title: 'Add Patient',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-add-circle-outline" />,
        }}
      >
        {props => <AddPatient {...props} user={user} auth={auth} />}
      </BottomTab.Screen>

      <BottomTab.Screen
        name="History"
        options={{
          title: 'History',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-archive" />,
        }}
      >
        {props => <PatientHistory {...props} user={user} auth={auth} />}
      </BottomTab.Screen>

      <BottomTab.Screen
        name="User"
        options={{
          title: 'User',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-person" />,
          unmountOnBlur: true,
        }}
      >
        {props => <User {...props} user={user} auth={auth} />}
      </BottomTab.Screen>

      {user.role == 0 &&
      <BottomTab.Screen
        name="Admin"
        options={{
          title: 'Admin',
          tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-people" />,
          unmountOnBlur: true,
        }}
      >
        {props => <Admin {...props} user={user} auth={auth}/>}
      </BottomTab.Screen>
      }

    </BottomTab.Navigator>
  );
}

function getHeaderTitle(route) {
  const routeName = route.state?.routes[route.state.index]?.name ?? INITIAL_ROUTE_NAME;

  switch (routeName) {
    case 'Dashboard':
      return 'Vitls Patient Monitor';
    case 'AddPatient':
      return 'Add a new patient to monitor';
    case 'User':
      return 'User Settings';
  }
}

function LogoTitle({user}) {

  return (
    <View style={[styles.row,{width:'100%',justifyContent:'space-around'}]}>
      <Image style={{ width: 160, height: 50 }} resizeMode={'contain'}
        source={require('../assets/images/vitlsLogo.png')} />
      {screenWidth > 1000 &&
        <>
          <View style={[styles.col,{alignItems:'center'}]}>
            <Text style={styles.cardHeaderValue}>{user.name.first} {user.name.last}</Text>
            <Text style={styles.cardHeaderLabel}>User</Text>
          </View>
          <View style={[styles.col,{alignItems:'center'}]}>
            <Text style={styles.cardHeaderValue}>{user.selectedRegistration.admissionCenterName}</Text>
            <Text style={styles.cardHeaderLabel}>Admission Center</Text>
          </View>
          <View style={[styles.col,{alignItems:'center'}]}>
            <Text style={styles.cardHeaderValue}>{user.selectedRegistration.wardName}</Text>
            <Text style={styles.cardHeaderLabel}>Ward</Text>
          </View>
        </>
      }

    </View>
  );
}
